import React from 'react';
import PropTypes from 'prop-types';
import VideoPopupCard from './VideoPopupCard';


function VideoStoryCard({ orientation, imageURL, videoId, videoType, title, text }) {
  return (
    <div className={`VideoStoryCard ${orientation}-orientation`}>
      <div className="VideoStoryCard-container">
        <VideoPopupCard 
          imageURL={imageURL}
          videoId={videoId}
          videoType={videoType}
          title={title}
          text={text}
        />
        <div className="VideoStoryCard-story">
          <h4 className="title">{title}</h4>
          <p className="story-content">{text}</p>
        </div>
      </div>
    </div>
  );
}

VideoStoryCard.defaultProps = {
  orientation: 'left' // can alternately supply 'right'
};

VideoStoryCard.propTypes = {
  imageURL: PropTypes.string, // for the static image of video
  videoId: PropTypes.string,  // for the video itself
  videoType: PropTypes.string,  // e.g., Vimeo, YouTube
  title: PropTypes.string,
  text: PropTypes.string
};

export default VideoStoryCard;