import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Section from '../Section';
import SectionHeader from '../SectionHeader';
import NumericFigure from '../../../common/data/NumericFigure';
import AnimatedNumber from '../../../common/data/AnimatedNumber';
import LazyLoad from '../../../../layout/LazyLoad';


function SnapshotSection({ content, scrollPos }) {
  const [animations, setAnimations] = useState([]);
  const [countless, setCountless] = useState(false);
  const { value: heading } = content['snapshot.heading'];
  const { value: number1 } = content['snapshot.number1'];
  const { value: number2 } = content['snapshot.number2'];
  const { value: number3 } = content['snapshot.number3'];
  const { value: number4 } = content['snapshot.number4'];
  const { value: countlessDescription } = content['snapshot.countless.description'];

  const onAnimate = useCallback(key => {
    setAnimations([ ...animations, key ]);
  }, [animations]);

  const handleCountless = number => {
    if(!countless && number === 1000000) {
      setCountless(true);
    }
  };

  return (
    <Section className="snapshot">
      <SectionHeader 
        heading={heading.title}
        subtext={heading.text}
      />
      <LazyLoad 
        id="stat-cards" 
        className="stat-cards-lazy" 
        position={scrollPos} 
        onLoad={onAnimate}
      >
        <div className="stat-cards">
          <NumericFigure 
            number={parseInt(number1.number)}
            description={number1.description}
            backgroundImage={number1.image}
            animated={number1.animated}
            formatted={true}
            startAnimation={animations.includes('stat-cards')}
          />
          <NumericFigure 
            number={parseInt(number2.number)}
            description={number2.description}
            backgroundImage={number2.image}
            animated={number2.animated}
            formatted={true}
            startAnimation={animations.includes('stat-cards')}
          />
          <NumericFigure 
            number={parseInt(number3.number)}
            description={number3.description}
            backgroundImage={number3.image}
            animated={number3.animated}
            formatted={true}
            startAnimation={animations.includes('stat-cards')}
          />
          <NumericFigure 
            number={parseInt(number4.number)}
            description={number4.description}
            backgroundImage={number4.image}
            animated={number4.animated}
            formatted={true}
            startAnimation={animations.includes('stat-cards')}
          />
        </div>
      </LazyLoad>
      <LazyLoad 
        id="countless" 
        className="countless-lazy" 
        position={scrollPos} 
        onLoad={onAnimate}
      >
        <div className="countless-container">
          { !countless &&
            <AnimatedNumber 
              number={1000000}
              onUpdate={handleCountless}
              startAnimation={animations.includes('countless')}
            />
          }
          { countless &&
            <p className="countless">Countless</p>
          }
          <p className="subtext">{countlessDescription}</p>
        </div>
      </LazyLoad>
    </Section>
  );
};


SnapshotSection.propTypes = {
  content: PropTypes.object,
  scrollPos: PropTypes.number
};

export default SnapshotSection;