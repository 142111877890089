import React, { useState, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import Section from '../Section';
import SectionHeader from '../SectionHeader';
import LazyLoad from '../../../../layout/LazyLoad';
import NumberComparison from '../../../common/data/NumberComparison';
import ImageStoryCard from '../../../common/ImageStoryCard';
import VideoStoryCard from '../../../common/VideoStoryCard';


function AbundanceSection({ content, scrollPos }) {
  const [animations, setAnimations] = useState([]);
  const { value: heading } = content['abundance.heading'];
  const { value: numberComparison1 } = content['abundance.numberComparison1'];
  const { value: numberComparison2 } = content['abundance.numberComparison2'];
  const { value: numberComparison3 } = content['abundance.numberComparison3'];
  const { value: numberComparison4 } = content['abundance.numberComparison4'];
  const { value: video } = content['abundance.video'];
  const { value: subsectionTitle } = content['abundance.sectionTitle'];
  const { value: story1 } = content['abundance.story1'];
  const { value: story2 } = content['abundance.story2'];
  const { value: story3 } = content['abundance.story3'];

  const onAnimate = useCallback(key => {
    setAnimations([ ...animations, key ]);
  }, [animations]);

  return (
    <Fragment>
      <Section className="abundance top">
        <SectionHeader 
          heading={heading.title}
          subtext={heading.text}
        />
        <LazyLoad 
          id="stat-cards" 
          className="stat-cards-lazy" 
          position={scrollPos} 
          onLoad={onAnimate}
        >
          <div className="stat-cards">
            <NumberComparison 
              title={numberComparison1.title}
              topNumber={parseInt(numberComparison1.topNumber)}
              topDescription={numberComparison1.topDescription}
              bottomNumber={parseInt(numberComparison1.bottomNumber)}
              bottomDescription={numberComparison1.bottomDescription}
              formatted={true}
              animated={numberComparison1.animated}
              startAnimation={animations.includes('stat-cards')}
            />
            <NumberComparison 
              title={numberComparison2.title}
              topNumber={parseInt(numberComparison2.topNumber)}
              topDescription={numberComparison2.topDescription}
              bottomNumber={parseInt(numberComparison2.bottomNumber)}
              bottomDescription={numberComparison2.bottomDescription}
              formatted={true}
              animated={numberComparison2.animated}
              startAnimation={animations.includes('stat-cards')}
            />
            <div className="break"></div>
            <NumberComparison 
              title={numberComparison3.title}
              topNumber={parseInt(numberComparison3.topNumber)}
              topDescription={numberComparison3.topDescription}
              bottomNumber={parseInt(numberComparison3.bottomNumber)}
              bottomDescription={numberComparison3.bottomDescription}
              formatted={true}
              animated={numberComparison3.animated}
              startAnimation={animations.includes('stat-cards')}
            />
            <NumberComparison 
              title={numberComparison4.title}
              topNumber={parseInt(numberComparison4.topNumber)}
              topDescription={numberComparison4.topDescription}
              bottomNumber={parseInt(numberComparison4.bottomNumber)}
              bottomDescription={numberComparison4.bottomDescription}
              formatted={true}
              animated={numberComparison4.animated}
              startAnimation={animations.includes('stat-cards')}
            />
          </div>
        </LazyLoad>
      </Section>
      <Section className="abundance bottom">
        <LazyLoad className="video1-lazy" position={scrollPos}>
          <div className="video-container">
            <VideoStoryCard 
              orientation="right"
              imageURL={video.image}
              videoId={video.videoId}
              videoType={video.videoProvider}
              title={video.title}
              text={video.text}
            />
          </div>
        </LazyLoad>
        <LazyLoad className="story-cards-lazy" position={scrollPos}>
          <h4 className="story-heading">{subsectionTitle}</h4>
          <div className="story-cards">
            <ImageStoryCard 
              imageURL={story1.image}
              altImageURL={story1.altImage}
              title={story1.title}
              subtitle={story1.subtitle}
              text={story1.text}
            />
            <ImageStoryCard 
              imageURL={story2.image}
              altImageURL={story2.altImage}
              title={story2.title}
              subtitle={story2.subtitle}
              text={story2.text}
            />
            <ImageStoryCard 
              imageURL={story3.image}
              altImageURL={story3.altImage}
              title={story3.title}
              subtitle={story3.subtitle}
              text={story3.text}
            />
          </div>
        </LazyLoad>
      </Section>
    </Fragment>
  );
};

AbundanceSection.propTypes = {
  content: PropTypes.object,
  scrollPos: PropTypes.number
};

export default AbundanceSection;