import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Section from '../Section';
import SectionHeader from '../SectionHeader';
//import VideoPopupCard from '../../../common/VideoPopupCard';

function ClosingSection({ content }) {
  const { value: heading } = content['closing.heading'];
  const { value: subText } = content['closing.subText'];
  //const { value: video } = content['closing.video'];
  const { value: button } = content['closing.button'];

  const openLink = url => {
    window.open(url, '_blank');
  };

  return (
    <Section className="closing">
      <SectionHeader 
        heading={heading.title}
      />
      <p>{subText}</p>
      {/* Removed per Father/Kelsey, but leaving code in case they change their minds
      <VideoPopupCard 
        imageURL={video.image}
        videoId={video.videoId}
        videoType={video.videoProvider}
        title={video.title}
      />
      */}
      <Button className="action" variant="outlined" onClick={() => openLink(button.url)}>
        {button.title}
      </Button>
    </Section>
  );
};

ClosingSection.propTypes = {
  content: PropTypes.object
};

export default ClosingSection;