import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as postAPI from '../../../actions/content/posts';
import NotFoundContent from '../../NotFoundContent';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import PageTitle from './PageTitle';
import stateConfig from '../../../config/state';
import config from '../../../config/config';
import errors from '../../../config/errors';
import urls from '../../../config/urls';
import { resetViewToTopOfPage } from '../../../utils/utils';
import validator from '../../../utils/validator';
const { isDefined, isNotDefined } = validator;


function Page({ posts, postAPI }) {
  const [loading, setLoading] = useState(true);
  const [slug, setSlug] = useState(null);
  const [page, setPage] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;
  let checkForMigratedURL = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => { // will run if location changes, handles switch between pages
    const slugStr = getSlug();
    if(isDefined(slug) && slugStr !== slug) {
      initialize();
    }
  }, [slug, location.pathname, initialize]);

  useEffect(() => {
    if(loading && isNotDefined(page)) {
      const pObj = posts.byId[`${stateConfig.keys.PAGE}.${slug}`];
      if(pObj) {
        setLoading(false);
        if(pObj.error) {
          setError(pObj.error);
        } else if(pObj.type === 'article') {
          navigate(urls.article.replace(':slug', slug));
        } else {
          setPage(pObj);
        }
      }
    }
  }, [loading, page, slug, posts.byId, posts.ids, navigate]);

  initialize = useCallback(() => {
    resetViewToTopOfPage();
    checkForMigratedURL();
    setLoading(true);
    setPage(null);

    let slugStr = getSlug();
    setSlug(slugStr);

    const pObj = posts.byId[`${stateConfig.keys.PAGE}.${slugStr}`];
    if(pObj && !pObj.error) {
      setPage(pObj);
    } else if(slugStr.includes('sunday-gospel') || slugStr.includes('roman-catholic-lectionary')) {
      navigate(urls.reflectionsLatin);
    } else if(slugStr.includes('byzantine-gospel') || slugStr.includes('byzantine-lectionary')) {
      // BGR hidden for the time being per Father's request in Oct 2023, may be permanently removed later
      //navigate(urls.reflectionsByzantine);
      navigate(urls.reflectionsLatin);
    } else {
      postAPI.getPageBySlug(slugStr);
    }
  }, [posts.byId, postAPI, checkForMigratedURL, navigate]);

  checkForMigratedURL = () => {
    const { pathname } = location;
    let matched = urls.migrationRedirects.find(item => item.oldPath === pathname);
    matched = matched || urls.migrationRedirects.find(item => `${item.oldPath}/` === pathname);
    if(matched && !matched.external) {
      navigate(matched.newPath);
    } else if(matched && matched.external) {
      window.location = matched.newPath;
    }
  };

  const getSlug = () => {
    if(window.location.pathname.includes('/register/')) {
      return window.location.pathname.replace('/register/', '');
    } else {
      return window.location.pathname.replace('/', '');
    }
  };

  const clearPopMessage = () => {
    setError(null);
  };
  
  return (
    <div className="MainContent-container">
      { !page && !error && <LoadingMessage message="Loading..." /> }

      { error && error.code === errors.ContentResourceNotFoundError.code &&
        <NotFoundContent />
      }

      { error && error.code !== errors.ContentResourceNotFoundError.code &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}>
          <p>We could not load the requested page.</p>
        </PopMessage>
      }

      { page && !error &&
        <div className={`Page ${page.width}`}>
          <PageTitle className={`display-${page.displayTitle? 'show' : 'hide'}`} type="Page" data={page} />
          <div className="PageContent" dangerouslySetInnerHTML={{__html: page.content}} />
        </div>
      }
    </div>
  );
}


function mapStateToProps(state) {
  return { 
    posts: state.posts 
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    postAPI: bindActionCreators(postAPI, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Page);