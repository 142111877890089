function useRefDimensions(ref) {
  let dimensions = { width: 0, height: 0 };
  if(ref && ref.current) {
    const { width, height } = ref.current.getBoundingClientRect();
    dimensions = { width: Math.round(width), height: Math.round(height) };
  }
  
  return dimensions;
}

export default useRefDimensions;