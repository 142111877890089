import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Element, scroller } from 'react-scroll';
import useAnalytics from '../../../hooks/useAnalytics';
import * as systemContentAPI from '../../../actions/content/system';
import SEOMeta from '../../common/SEOMeta';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import BottomNavigation from './annual/BottomNavigation';
import HeadingSection from './annual/sections/HeadingSection';
import WelcomeSection from './annual/sections/WelcomeSection';
import SnapshotSection from './annual/sections/SnapshotSection';
import AbundanceSection from './annual/sections/AbundanceSection';
import WorldwideSection from './annual/sections/WorldwideSection';
import DepthSection from './annual/sections/DepthSection';
import CommunitiesSection from './annual/sections/CommunitiesSection';
import DomesticChurchSection from './annual/sections/DomesticChurchSection';
import FinancesSection from './annual/sections/FinancesSection';
import StaffSection from './annual/sections/StaffSection';
import BoardSection from './annual/sections/BoardSection';
import ClosingSection from './annual/sections/ClosingSection';
import QuoteInterstitial from './annual/interstitials/QuoteInterstitial';
import ImageQuoteInterstitial from './annual/interstitials/ImageQuoteInterstitial';
import ImageContentInterstitial from './annual/interstitials/ImageContentInterstitial';
import VideoPopupInterstitial from './annual/interstitials/VideoPopupInterstitial';
import stateConfig from '../../../config/state';
import config from '../../../config/config';
import { resetViewToTopOfPage } from '../../../utils/utils';
import '../../../styles/alt-heading-font.css';


const anchorNav = ['#snapshot', '#abundance', '#worldwide', '#depth', '#communities', '#domestic-church', '#finances', '#staff'];

function AnnualReport({ systemContent, systemContentAPI }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [displayBottomNav, setDisplayBottomNav] = useState(false);
  const [scrollPos, setScrollPos] = useState(0);
  const [content, setContent] = useState(null);
  const [interstitials, setInterstitials] = useState(null);
  const [anchor, setAnchor] = useState(null);
  useAnalytics('Annual Report');
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const reportContent = systemContent.annualReport;
    if(loading && !Boolean(content) && Boolean(reportContent)) {
      setLoading(false);
      if(Boolean(reportContent.error) || !Boolean(reportContent.settings)) {
        setError(true);
      } else {
        setContent(parseContent(reportContent.settings));
        setInterstitials(parseInterstitials(reportContent.settings));
        setTimeout(() => {
          onNavigate(anchor, false);
        }, 1000);
      }
    }
  }, [loading, content, anchor, systemContent.annualReport]);

  initialize = () => {
    resetViewToTopOfPage();
    setAnchor(anchorNav.findIndex(item => item === window.location.hash));
    const reportContent = systemContent.annualReport;
    if(Boolean(reportContent) && Boolean(reportContent.settings)) {
      setContent(parseContent(reportContent.settings));
      setInterstitials(parseInterstitials(reportContent.settings));
      setLoading(false);
      setTimeout(() => {
        onNavigate(anchor, false);
      }, 500);
    } else {
      systemContentAPI.getAnnualReport();
      }
  };

  const handleScroll = useCallback(() => {
    const position = window.pageYOffset;
    setScrollPos(position);
    if(!displayBottomNav && position >= 2600) {
      setDisplayBottomNav(true);
    } else if(displayBottomNav && position < 2600) {
      setDisplayBottomNav(false);
    }
  }, [displayBottomNav]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const onNavigate = (navIndex, smooth = true) => {
    switch(navIndex) {
      case 0:
        scroller.scrollTo('snapshot-scroll', { smooth });
        break;
      case 1:
        scroller.scrollTo('abundance-scroll', { smooth });
        break;
      case 2:
        scroller.scrollTo('worldwide-scroll', { smooth });
        break;
      case 3:
        scroller.scrollTo('depth-scroll', { smooth });
        break;
      case 4:
        scroller.scrollTo('communities-scroll', { smooth });
        break;
      case 5:
        scroller.scrollTo('domestic-church-scroll', { smooth });
        break;
      case 6:
        scroller.scrollTo('finances-scroll', { smooth });
        break;
      case 7:
        scroller.scrollTo('staff-scroll', { smooth: true });
        break;
      case 8:
        scroller.scrollTo('donate-scroll', { smooth: true });
        break;
      default:
        // do nothing
    }
  };

  const parseContent = (settings) => {
    return settings.reduce((obj, setting) => {
      const { key, type, value } = setting;
      obj[key] = { type, value };
      return obj;
    }, {});
  };

  const parseInterstitials = (settings) => {
    return settings.reduce((obj, setting) => {
      const { key, value } = setting;
      if(key.includes('interstitial')) {
        obj[key] = value;
      }
      return obj;
    }, {});
  };

  const handleButton = buttonConfig => () => {
    if(buttonConfig.donationAction) {
      scroller.scrollTo('donate-scroll', { smooth: true });
    } else {
      window.open(buttonConfig.url, '_blank');
    }
  };

  const clearPopMessage = () => {
    setError(false);
  };

  return (
    <div className="AnnualReport">
      <SEOMeta title="Our Year in Review" description="Annual Report 2022" />

      { loading && !error && <LoadingMessage message="Loading..." /> }
      { error &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}>
          <p>We could not load the requested page.</p>
        </PopMessage>
      }

      { !loading && Boolean(content) &&
        <Fragment>
          <HeadingSection content={content} />
          <WelcomeSection content={content} onNavigate={onNavigate} />
          <QuoteInterstitial
            className="welcome"
            text={interstitials['welcome.interstitial'].text}
            name={interstitials['welcome.interstitial'].name}
            scrollPos={scrollPos}
          />
          <Element name="snapshot-scroll"></Element>
          <SnapshotSection content={content} scrollPos={scrollPos} />
          <ImageQuoteInterstitial 
            className="snapshot"
            imageURL={interstitials['snapshot.interstitial'].image}
            text={interstitials['snapshot.interstitial'].text}
            name={interstitials['snapshot.interstitial'].name}
            buttonTitle={interstitials['snapshot.interstitial'].button.title}
            onClick={handleButton(interstitials['snapshot.interstitial'].button)}
            scrollPos={scrollPos}
          />
          <Element name="abundance-scroll"></Element>
          <AbundanceSection content={content} scrollPos={scrollPos} />
          <ImageContentInterstitial 
            className="abundance"
            imageURL={interstitials['abundance.interstitial'].image}
            buttonTitle={interstitials['abundance.interstitial'].button.title}
            onClick={handleButton(interstitials['abundance.interstitial'].button)}
            content={(<div dangerouslySetInnerHTML={{ __html: interstitials['abundance.interstitial'].content }}></div>)}
            scrollPos={scrollPos}
          />
          <Element name="worldwide-scroll"></Element>
          <WorldwideSection content={content} scrollPos={scrollPos} />
          <ImageQuoteInterstitial 
            className="worldwide"
            orientation="right"
            imageURL={interstitials['worldwide.interstitial'].image}
            text={interstitials['worldwide.interstitial'].text}
            name={interstitials['worldwide.interstitial'].name}
            buttonTitle={interstitials['worldwide.interstitial'].button.title}
            onClick={handleButton(interstitials['worldwide.interstitial'].button)}
            scrollPos={scrollPos}
          />
          <Element name="depth-scroll"></Element>
          <DepthSection content={content} scrollPos={scrollPos} />
          <ImageQuoteInterstitial 
            className="depth"
            imageURL={interstitials['depth.interstitial'].image}
            text={interstitials['depth.interstitial'].text}
            name={interstitials['depth.interstitial'].name}
            buttonTitle={interstitials['depth.interstitial'].button.title}
            onClick={handleButton(interstitials['depth.interstitial'].button)}
            scrollPos={scrollPos}
          />
          <Element name="communities-scroll"></Element>
          <CommunitiesSection content={content} scrollPos={scrollPos} />
          <VideoPopupInterstitial 
            className="communities"
            orientation="right"
            imageURL={interstitials['communities.interstitial'].image}
            videoId={interstitials['communities.interstitial'].videoId}
            videoType={interstitials['communities.interstitial'].videoProvider}
            videoTitle={interstitials['communities.interstitial'].title}
            text={interstitials['communities.interstitial'].text}
            buttonTitle={interstitials['communities.interstitial'].button.title}
            onClick={handleButton(interstitials['communities.interstitial'].button)}
            scrollPos={scrollPos}
          />
          <Element name="domestic-church-scroll"></Element>
          <DomesticChurchSection content={content} scrollPos={scrollPos} />
          <ImageQuoteInterstitial 
            className="domestic-church"
            imageURL={interstitials['domesticChurch.interstitial'].image}
            text={interstitials['domesticChurch.interstitial'].text}
            name={interstitials['domesticChurch.interstitial'].name}
            buttonTitle={interstitials['domesticChurch.interstitial'].button.title}
            onClick={handleButton(interstitials['domesticChurch.interstitial'].button)}
            scrollPos={scrollPos}
          />
          <Element name="finances-scroll"></Element>
          <FinancesSection content={content} scrollPos={scrollPos} />
          <ImageQuoteInterstitial 
            className="finances"
            imageURL={interstitials['finances.interstitial'].image}
            text={interstitials['finances.interstitial'].text}
            name={interstitials['finances.interstitial'].name}
            buttonTitle={interstitials['finances.interstitial'].button.title}
            onClick={handleButton(interstitials['finances.interstitial'].button)}
            scrollPos={scrollPos}
          />
          <Element name="staff-scroll"></Element>
          <StaffSection content={content} scrollPos={scrollPos} />
          <BoardSection scrollPos={scrollPos} />
          <ClosingSection content={content} />
          <BottomNavigation display={displayBottomNav} content={content} onNavigate={onNavigate} />
        </Fragment>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    systemContent: state.systemContent
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    systemContentAPI: bindActionCreators(systemContentAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AnnualReport);