import React from 'react';
import PropTypes from 'prop-types';
import Section from '../Section';
import LeadershipContent from '../../../about/LeadershipContent';
import LazyLoad from '../../../../layout/LazyLoad';


function BoardSection({ scrollPos }) {
  return (
    <Section className="board">
      <LazyLoad className="leadership-lazy" position={scrollPos}>
        <LeadershipContent 
          displayAdvisors={false} 
          onComplete={() => {}} 
        />
      </LazyLoad>
    </Section>
  );
};

BoardSection.propTypes = {
  scrollPos: PropTypes.number
};

export default BoardSection;