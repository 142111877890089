import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { themeColors } from '../../../../styles/Theme';


function PieChart({ data, labels, title, duration, colors, diameter, startAnimation }) {
  const chartRef = useRef(null);
  const [init, setInit] = useState(false);

  const draw = useCallback(() => {
    let chartColors = colors.map(c => themeColors[c]);
    let chartEl = chartRef.current;
    let outerRadius = parseInt(diameter/2);

    d3.select(chartEl).html("");
    
    let generator = d3.pie().sort(null);
  
    let chart = generator(data);
  
    let arcs = d3.select(chartEl)
      .append("g")
      .attr("transform", `translate(${outerRadius}, ${outerRadius})`)
      .selectAll("path")
      .data(chart)
      .enter()
      .append("path")
      .style("fill", (d, i) => chartColors[i])
      .style("stroke", "white")
      .style("stroke-width", "1px");
  
    let angleInterpolation = d3.interpolate(generator.startAngle()(), generator.endAngle()());
  
    let arc = d3.arc();
    
    arcs.transition()
      .duration(duration)
      .attrTween("d", d => {
        let originalEnd = d.endAngle;
        return t => {
          let currentAngle = angleInterpolation(t);
          if (currentAngle < d.startAngle) {
            return "";
          }
  
          d.endAngle = Math.min(currentAngle, originalEnd);
  
          return arc(d);
        };
      });
    
    d3.select(chartEl)
      .transition()
      .duration(duration)
      .tween("arcRadii", () => {
        return t => arc
        .innerRadius(0)
        .outerRadius(outerRadius);
      });
  }, [data, duration, colors, diameter]);

  useEffect(() => {
    if(!init && startAnimation) {
      draw();
      setInit(true);
    }
  }, [init, startAnimation, draw]);

  return (
    <div className="PieChart">
      { Boolean(title) &&
        <h4>{title}</h4>
      }
      <div className="PieChart-container">
        <svg 
          ref={chartRef} 
          className="PieChart-svg" 
          preserveAspectRatio="xMinYMin meet" 
          viewBox={`0 0 ${diameter} ${diameter}`}>
        </svg>
        <ul className="PieChart-legend">
          {
            data.map((percentage, index) => {
              return (
                <li key={`li-${index}`}>
                  <span className="percentage">{`${percentage}%`}</span>
                  <span className="color" style={{ backgroundColor: themeColors[colors[index]] }}></span>
                  <span className="label">{labels[index]}</span>
                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  );
}

PieChart.defaultProps = {
  duration: 2000,
  colors: ['darkBlue', 'mediumBlue', 'lightBlue', 'gold', 'gray', 'lightGray'],
  diameter: 200,
  startAnimation: true
};

PieChart.propTypes = {
  data: PropTypes.array,
  labels: PropTypes.array,
  title: PropTypes.string
};

export default PieChart;