import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Section from '../Section';
import SectionHeader from '../SectionHeader';
import NumericFigure from '../../../common/data/NumericFigure';
import MapHighlights from '../../../common/data/MapHighlights';
import ScrollingBackground from '../../../common/ScrollingBackground';
import LazyLoad from '../../../../layout/LazyLoad';
import config from '../../../../../config/config';


function WorldwideSection({ content, scrollPos }) {
  const [animations, setAnimations] = useState([]);
  const [scrollListData, setScrollListData] = useState(null);
  const { value: heading } = content['worldwide.heading'];
  const { value: countries } = content['worldwide.countries'];
  const { value: number1 } = content['worldwide.number1'];
  const { value: number2 } = content['worldwide.number2'];
  const { value: number3 } = content['worldwide.number3'];
  const { value: map } = content['worldwide.map'];
  const mapSectionKeys = Object.keys(content).filter(item => item.includes('worldwide.mapSection'));
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  initialize = () => {
    setScrollListData(countries.split("\r\n"));
  };

  const onAnimate = useCallback(key => {
    setAnimations([ ...animations, key ]);
  }, [animations]);

  return (
    <Section className="worldwide-family">
      <SectionHeader 
        heading={heading.title}
        subtext={heading.text}
      />
      { Boolean(scrollListData) &&
        <div className="scroll-list">
          <div className="countries-container">
            <div className="countries-list">
              <ScrollingBackground position={scrollPos} divisor={17}>
                <ul className="countries">
                  { 
                    scrollListData.map((item, index) => {
                      return (<li key={`country-${index}`}>{item}</li>);
                    }) 
                  }
                </ul>
              </ScrollingBackground>
            </div>
            <div className="arrow">
              <img src="https://instituteofcatholicculture.net/wp-content/uploads/2022/11/long-arrow.png" alt="Arrow" />
            </div>
            <LazyLoad 
              id="list-numbers" 
              className="countries-highlights list-numbers-lazy" 
              position={scrollPos} 
              onLoad={onAnimate}
            >
              <NumericFigure 
                number={parseInt(number1.number)}
                description={number1.description}
                animated={number1.animated}
                formatted={true}
                startAnimation={animations.includes('list-numbers')}
              />
              <NumericFigure 
                number={parseInt(number2.number)}
                description={number2.description}
                animated={number2.animated}
                formatted={true}
                startAnimation={animations.includes('list-numbers')}
              />
              <NumericFigure 
                number={parseInt(number3.number)}
                description={number3.description}
                animated={number3.animated}
                formatted={true}
                percent={true}
                startAnimation={animations.includes('list-numbers')}
              />
            </LazyLoad>
          </div>
        </div>
      }
      <LazyLoad className="map-lazy" position={scrollPos}>
        <MapHighlights 
          title={map.title}
          sections={mapSectionKeys.map(key => {
            const { id, title, text, image: imageURL } = content[key].value;
            return { id, title, text, imageURL };
          })}
          locations={map.pinLocations.map(location => {
            const { id, longitude, latitude } = location;
            return { id, long: parseFloat(longitude), lat: parseFloat(latitude) };
          })}
          highlights={map.highlightedCountries}
        />
      </LazyLoad>
    </Section>
  );
};

WorldwideSection.propTypes = {
  content: PropTypes.object,
  scrollPos: PropTypes.number
};

export default WorldwideSection;