import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import querystring from 'qs';
import * as authenticationAPI from '../../../actions/authentication';
import EventData from '../../data/Event';
import DetailHeading from './detail/DetailHeading';
import DetailCourseApplication from './detail/DetailCourseApplication';
import DetailSharing from './detail/DetailSharing';
import DetailParticipation from './detail/DetailParticipation';
import DetailDescription from './detail/DetailDescription';
import Recommendations from '../common/Recommendations';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import Watched from '../../data/Watched';
import NotFoundContent from '../../NotFoundContent';
import stateConfig from '../../../config/state';
import errors from '../../../config/errors';
import config from '../../../config/config';
import urls from '../../../config/urls';
import { resetViewToTopOfPage } from '../../../utils/utils';
import { cleanEmailInput } from '../../../utils/input';
import validator from '../../../utils/validator';
import DetailEventApplicationStatus from './detail/DetailEventApplicationStatus';
const { isEmpty, isNotEmpty } = validator;


function Event({ user, authenticationAPI, eventRegistration }) {
  const [slug, setSlug] = useState(null);
  const [event, setEvent] = useState(null);
  const [error, setError] = useState(null);
  const [watchedData, setWatchedData] = useState(null);
  const [openClassroomType, setOpenClassroomType] = useState(null); 
  const [referral, setReferral] = useState({ processed: false });
  const [registeredForEvent, setRegisteredForEvent] = useState(null); 
  const navigate = useNavigate();
  const location = useLocation();
  const isCompleted = event && (event.status === 'Completed' || event.status === 'Completed - Pending');
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;
  let checkForReferral = config.emptyFn;
  let openClassroom = config.emptyFn;


  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const { pathname } = location;
    const regex = /\/events\/\w+/;
    if(regex.test(pathname)) {
      if(!pathname.includes('/events/categories') && !pathname.includes('/events/list')) {
        setSlug(getSlug());
        setEvent(null);
        setError(null);
        resetViewToTopOfPage();
      }
    }
  }, [location]);

  useEffect(() => {
    if(isNotEmpty(openClassroomType && isNotEmpty(watchedData))) {
      openClassroom(openClassroomType); 
    }
  }, [openClassroomType, openClassroom, watchedData]);

  useEffect(() => {
    if(!referral.processed && user.userDataChecked) {
      if(isEmpty(user.email)) {
        checkForReferral();
      } else {
        setReferral({ processed: true });
      }
    }
  }, [referral, user.userDataChecked, user.email, checkForReferral]);

  useEffect(() => {
    if(eventRegistration.responseStatus !== 'COMPLETE') {
       return; 
    }

    if(eventRegistration.ids && eventRegistration.ids.length && event && event.id) {
      const registration = eventRegistration.byId[event.id]; 
      if(registration && registration.registered) {
        setRegisteredForEvent(true); 
      }
    }
  }, [eventRegistration, event]); 

  initialize = () => {
    resetViewToTopOfPage();
    setSlug(getSlug());
  };

  const getSlug = () => {
    const pathname = window.location.pathname;
    if(pathname.includes('/talk')) { // handling old URL refs
      return pathname.replace(/\/talk\/|\//g, '');
    } else {
      return pathname.replace(/\/events\/|\//g, '');
    }
  };

  checkForReferral = () => {
    const params = querystring.parse(location.search.substring(1));
    const email = cleanEmailInput(params.email) || '';
    if(isEmpty(user.email) && isNotEmpty(email)) {
      // no user email, but one was passed in, go ahead and auto-ID with the passed in email
      setReferral({ processed: true, email });
      authenticationAPI.identifyWithEmail(email).catch(() => {});
    } else {
      setReferral({ processed: true });
    }
  };

  const onEventLoaded = (data) => {
    if(isNotEmpty(data.error)) {
      setError(data.error);
    } else if(isNotEmpty(data.event) && isEmpty(event)) {
      setEvent(data.event);
    }
  };

  const onWatchedLoaded = (data) => {
    setWatchedData(data);
  };

  openClassroom = useCallback((mediaType) => {
    // allow to proceed if watched data has loaded, or if there is no identified user (e.g., trial access)
    const canProceed = isNotEmpty(user.email)? isNotEmpty(watchedData) : true;
    if(canProceed) {
      setOpenClassroomType(null); 
      if(mediaType === 'audio') {
        const mediaId = isNotEmpty(watchedData)? watchedData.nextAudio.media.id : event.media.audio[0].id;
        navigate(urls.eventsClassroomAudio.replace(':slug', slug).replace(':audioId', mediaId));
      } else {
        const mediaId = isNotEmpty(watchedData)? watchedData.nextVideo.media.id : event.media.video[0].id;
        navigate(urls.eventsClassroomVideo.replace(':slug', slug).replace(':videoId', mediaId));
      }
    } else {
      // want to wait for watched data to load
      setOpenClassroomType(mediaType); 
    }
  },[navigate, user, event, slug, watchedData]);

  const clearPopMessage = () => {
    setError(null);
  };

  return (
    <div className="MainContent-container">
      { isEmpty(event) && isEmpty(error) && <LoadingMessage message="Loading..." /> }

      { isNotEmpty(slug) && <EventData slug={slug} onEventLoaded={onEventLoaded} /> }

      { isNotEmpty(error) && error.code === errors.ContentResourceNotFoundError.code &&
        <NotFoundContent />
      }

      { isNotEmpty(error) && error.code !== errors.ContentResourceNotFoundError.code &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}>
          <p>We could not load the requested page.</p>
        </PopMessage>
      }

      { isNotEmpty(event) && isEmpty(error) &&
        <Fragment>
          <Watched content={event} onWatchedLoaded={onWatchedLoaded} />
          <div className="EventDetail">
            <div className="content">
              <DetailHeading 
                eventData={event} 
                referral={referral}
                onOpenClassroom={openClassroom} 
              />
              { event.type === 'Live Course' && event.status === 'Upcoming' &&
                <DetailCourseApplication eventData={event} />
              }
              { (event.type === 'Lecture' || event.type === 'Lecture Series') && event.status === 'Upcoming' && user.userDataChecked && user.email && registeredForEvent &&
                <DetailEventApplicationStatus />
              }
              <DetailSharing eventData={event} />
              { !isCompleted &&
                <DetailParticipation eventData={event} />
              }
              <DetailDescription eventData={event} />
            </div>
          </div>
        </Fragment>
      }

      { isNotEmpty(event) && event.categories && event.categories.length?
        <Recommendations eventData={event} /> :
        <span>&nbsp;</span>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    user: state.user,
    eventRegistration: state.eventRegistration
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    authenticationAPI: bindActionCreators(authenticationAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Event);