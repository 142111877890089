import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Section from '../Section';
import SectionHeader from '../SectionHeader';
import ImageStoryCard from '../../../common/ImageStoryCard';
import Carousel from '../../../common/Carousel';
import BasicCarouselCard from '../../../common/BasicCarouselCard';
import LazyLoad from '../../../../layout/LazyLoad';


function StaffSection({ content, scrollPos }) {
  const { value: heading } = content['staff.heading'];
  const { value: carousel } = content['staff.carousel'];
  const staffKeys = Object.keys(content).filter(item => item.includes('staff.employee'));

  return (
    <Fragment>
      <Section className="staff top">
        <SectionHeader 
          heading={heading.title}
          subtext={heading.text}
        />
        <LazyLoad className="staff-cards-lazy" position={scrollPos}>
          <div className="staff-cards">
            {
              staffKeys.map((key, index) => {
                const { image, altImage, title, subtitle, text } = content[key].value;
                return (
                  <ImageStoryCard 
                    key={`staff-card-${index}`}
                    imageURL={image}
                    altImageURL={altImage}
                    title={title}
                    subtitle={subtitle}
                    text={text}
                  />
                );
              })
            }
          </div>
        </LazyLoad>
      </Section>
      <Section className="staff bottom-carousel">
        <div className="carousel-container">
          <LazyLoad className="staff-carousel-lazy" position={scrollPos}>
            <Carousel title={carousel.title} subtitle={carousel.subtitle}>
              {
                carousel.cards.map((card, index) => {
                  const { image: imageURL, title, text } = card;
                  return (
                    <BasicCarouselCard 
                      key={`staff-carousel-card-${index}`}
                      id={`bc-card${index+1}`}
                      imageURL={imageURL}
                      title={title}
                      text={text}
                    />
                  );
                })
              }
            </Carousel>
          </LazyLoad>
        </div>
      </Section>
    </Fragment>
  );
};

StaffSection.propTypes = {
  content: PropTypes.object,
  scrollPos: PropTypes.number
};

export default StaffSection;