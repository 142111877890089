import config from "../config/config";

export function detectDevice() {
  return {
    agent: agentInformation(),
    os: operatingSystem()
  };
}

export function deviceDescription() {
  const agent = agentInformation();
  const os = operatingSystem();
  let description = 'No description available';

  if(agent && agent.device) {
    description = agent.browser? `Device: ${agent.device}, Browser: ${agent.browser}` : `Device: ${agent.device}`;
  } else if(agent && agent.browser) {
    description = os? `Device: ${os}, Browser: ${agent.browser}` : `Browser: ${agent.browser}`;
  } else if(os) {
    description = `Device: ${os}`;
  }

  return description;
}


export function viewport() {
  const viewportWidth = document.documentElement.clientWidth;
  const { deviceBreakpoints: {large, desktop, medium, tablet, small, phone, smallPhone } } = config;
  const flags = { isLarge: false, isSmall: false, isPhone: false };

  if(viewportWidth > large) {
    return { width: viewportWidth, deviceClass: 'veryLarge', ...flags, isLarge: true };
  } else if(viewportWidth > desktop) {
    return { width: viewportWidth, deviceClass: 'large', ...flags, isLarge: true };
  } else if(viewportWidth > medium) {
    return { width: viewportWidth, deviceClass: 'desktop', ...flags };
  } else if(viewportWidth > tablet) {
    return { width: viewportWidth, deviceClass: 'medium', ...flags };
  } else if(viewportWidth > small) {
    return { width: viewportWidth, deviceClass: 'tablet', ...flags };
  } else if(viewportWidth > phone) {
    return { width: viewportWidth, deviceClass: 'small', ...flags, isSmall: true };
  } else if(viewportWidth > smallPhone) {
    return { width: viewportWidth, deviceClass: 'phone', ...flags, isSmall: true, isPhone: true };
  } else {
    return { width: viewportWidth, deviceClass: 'smallPhone', ...flags, isSmall: true, isPhone: true };
  }
}

function agentInformation() {
  let info = {};
  const agent = window.navigator.userAgent || '';

  if(agent.indexOf("Firefox") > -1) {
    info.browser = 'Mozilla Firefox';
  } else if(agent.indexOf("Opera") > -1) {
    info.browser = 'Opera';
  } else if (agent.indexOf("Trident") > -1) {
    info.browser = 'Microsoft Internet Explorer';
  } else if (agent.indexOf("Edge") > -1) {
    info.browser = 'Microsoft Edge';
  } else if (agent.indexOf("Chrome") > -1) {
    info.browser = 'Google Chrome/Chromium';
  } else if (agent.indexOf("Safari") > -1) {
    info.browser = 'Apple Safari';
    info.restrictsXDomCookies = true;
  }

  if(agent.indexOf('Windows') > -1) {
    info.device = 'Windows';
  } else if(agent.indexOf('iPhone') > -1) {
    info.device = 'iPhone';
  } else if(agent.indexOf('iPad') > -1) {
    info.device = 'iPad';
  } else if(agent.indexOf('iPhone') > -1) {
    info.device = 'iPod';
  } else if(agent.indexOf('Mac') > -1) {
    info.device = 'Mac';
  }

  return (info.browser || info.device)? info : null;
}

function operatingSystem() {
  const oscpu = window.navigator.oscpu || '';
  if(oscpu.indexOf('Windows') > -1) {
    return 'Windows';
  } else if(oscpu.indexOf('Mac') > -1) {
    return 'Mac';
  } else {
    return null;
  }
}
