import React, { Fragment, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import useAnalytics from '../../../hooks/useAnalytics';
import * as instructorsAPI from '../../../actions/content/instructors';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import urls from '../../../config/urls';
import stateConfig from '../../../config/state';
import config from '../../../config/config';
import { resetViewToTopOfPage } from '../../../utils/utils';
import SEOMeta from '../../common/SEOMeta';
import validator from '../../../utils/validator';
const { isEmpty, isNotEmpty } = validator;


function Instructors({ instructors, instructorsAPI }) {
  const [loading, setLoading] = useState(true);
  const [instructorList, setInstructorList] = useState(null);
  const [error, setError] = useState(null);
  const stateId = `${stateConfig.keys.INSTRUCTOR_LIST}.instructors`;
  const navigate = useNavigate();
  useAnalytics('Instructors');
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const data = instructors.byId[stateId];
    if(loading && isEmpty(instructorList) && instructors.ids && isNotEmpty(data)) {
      if(data.error) {
        setError(instructors.error);
      } else {
        setInstructors(data);
      }
      setLoading(false);
    }
  }, [loading, stateId, instructorList, instructors, instructors.ids]);

  initialize = () => {
    resetViewToTopOfPage();
    const { byId } = instructors;
    const data = byId[stateId];
    if(data && !data.error) {
      setInstructors(data);
      setLoading(false);
    } else {
      instructorsAPI.getInstructors();
    }
  };

  const setInstructors = data => {
    const filtered = data.results.filter(instructor => instructor.count > 0);
    setInstructorList(filtered);
  };

  const onInstructorSelection = id => () => {
    navigate(urls.instructor.replace(':instructorId', id));
  };

  const clearPopMessage = () => {
    setError(null);
  };
  
  return (
    <div className="MainContent-container">
      { !instructorList && !error && <LoadingMessage message="Loading..." /> }
      
      <div className="Instructors-container">
        { error &&
          <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
            type={stateConfig.messageTypes.ERROR}>
            <p>We could not load the requested page.</p>
          </PopMessage>
        }

        { instructorList && 
          <Fragment>
            <SEOMeta 
              title="Instructors" 
              description="Explore our fantastic instructors, and discover the courses and lectures they have taught for the Institute." />
            <h2>Our Instructors</h2>
            <div className="Instructor-list">
            {
              instructorList.map(instructor => {
                const { id, name, imageURL, count } = instructor;
                return (
                  <div className="Instructor" key={id} onClick={onInstructorSelection(id)}>
                    <div className="image"><img src={imageURL} alt={name} /></div>
                    <div className="info">
                      <div className="name">
                        <Link to={urls.instructor.replace(':instructorId', id)}>{name}</Link>
                      </div>
                      <div className="programs">{`${count} events`}</div>
                    </div>
                  </div>
                );
              })
            }
            </div>
          </Fragment>
        }
      </div>
    </div>
  );
}


function mapStateToProps(state) {
  return { instructors: state.instructors };
}

function mapDispatchToProps(dispatch) {
  return { 
    instructorsAPI: bindActionCreators(instructorsAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Instructors);
