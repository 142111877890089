import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Section from '../Section';
import VideoPopupCard from '../../../common/VideoPopupCard';
import LazyLoad from '../../../../layout/LazyLoad';


function VideoPopupInterstitial({ className, orientation, buttonVariant, imageURL, videoId, videoType, videoTitle, text, buttonTitle, onClick, scrollPos }) {
  return (
    <Section className={`video-popup interstitial ${className} ${orientation}`}>
      <LazyLoad className="container" position={scrollPos}>
        { orientation === 'left' &&
          <Fragment>
            <div className="col1 large-display">
              <p>{text}</p>
            </div>
            <div className="col2 large-display">
              <VideoPopupCard 
                imageURL={imageURL}
                videoId={videoId}
                videoType={videoType}
                title={videoTitle}
              />
              { Boolean(buttonTitle) && Boolean(onClick) &&
                <Button className="action" variant={buttonVariant} onClick={onClick}>
                  {buttonTitle}
                </Button>
              }
            </div>
          </Fragment>
        }
        { orientation === 'right' &&
          <Fragment>
            <div className="col1 large-display">
              <VideoPopupCard 
                imageURL={imageURL}
                videoId={videoId}
                videoType={videoType}
                title={videoTitle}
              />
              { Boolean(buttonTitle) && Boolean(onClick) &&
                <Button className="action" variant={buttonVariant} onClick={onClick}>
                  {buttonTitle}
                </Button>
              }
            </div>
            <div className="col2 large-display">
              <p>{text}</p>
            </div>
          </Fragment>
        }
        <div className="small-display">
          <VideoPopupCard 
            imageURL={imageURL}
            videoId={videoId}
            videoType={videoType}
            title={videoTitle}
          />
          <p>{text}</p>
          { Boolean(buttonTitle) && Boolean(onClick) &&
            <Button className="action" variant={buttonVariant} onClick={onClick}>
              {buttonTitle}
            </Button>
          }
        </div>
      </LazyLoad>
    </Section>
  );
};

VideoPopupInterstitial.defaultProps = {
  className: '',
  orientation: 'left',        // alt. 'right'
  buttonVariant: 'outlined'
}

VideoPopupInterstitial.propTypes = {
  imageURL: PropTypes.string,
  videoId: PropTypes.string,
  videoType: PropTypes.string,
  videoTitle: PropTypes.string,
  text: PropTypes.string,
  buttonTitle: PropTypes.string,
  onClick: PropTypes.func,
  scrollPos: PropTypes.number
};

export default VideoPopupInterstitial;