import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Section from '../Section';
import SectionHeader from '../SectionHeader';
import Carousel from '../../../common/Carousel';
import BasicCarouselCard from '../../../common/BasicCarouselCard';
import VideoStoryCard from '../../../common/VideoStoryCard';
import LazyLoad from '../../../../layout/LazyLoad';


function DomesticChurchSection({ content, scrollPos }) {
  const { value: heading } = content['domesticChurch.heading'];
  const { value: video } = content['domesticChurch.video'];
  const { value: carousel } = content['domesticChurch.carousel'];

  return (
    <Fragment>
      <Section className="domestic-church top">
        <SectionHeader 
          heading={heading.title}
          subtext={heading.text}
        />
        <LazyLoad className="video-lazy" position={scrollPos}>
          <div className="video-container1">
            <VideoStoryCard 
              orientation="right"
              imageURL={video.image}
              videoId={video.videoId}
              videoType={video.videoProvider}
              title={video.title}
              text={video.text}
            />
          </div>
        </LazyLoad>
      </Section>
      <Section className="domestic-church bottom-carousel">
        <div className="carousel-container">
          <LazyLoad className="carousel-lazy" position={scrollPos}>
            <Carousel title={carousel.title}>
              {
                carousel.cards.map((card, index) => {
                  const { image: imageURL, title, text } = card;
                  return (
                    <BasicCarouselCard 
                      key={`domestic-church-carousel-card-${index}`}
                      id={`bc-card${index+1}`}
                      imageURL={imageURL}
                      title={title}
                      text={text}
                    />
                  );
                })
              }
            </Carousel>
          </LazyLoad>
        </div>
      </Section>
    </Fragment>
  );
};

DomesticChurchSection.propTypes = {
  content: PropTypes.object,
  scrollPos: PropTypes.number
};

export default DomesticChurchSection;