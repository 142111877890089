import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import querystring from 'qs';
import Button from '@mui/material/Button';
import Icon, { IconTypes } from './common/Icon';
import urls from '../config/urls';


function NotFoundContent() {
  const [source, setSource] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if(source === null) {
      const params = querystring.parse(location.search.substring(1));
      setSource(params.s || 'default');
    }
  }, [source, location.search]);

  const generateMessage = () => {
    switch(source) {
      case 'course':
        return 'The course page you are looking for has moved.';
      default:
        return 'The page you are looking for could not be located or has moved.';
    }
  };

  const renderNavigation = () => {
    if(source === 'course') {
      return (
        <div className="NotFound-navigation">
          <Button variant="contained" color="primary" onClick={() => navigate(urls.courses)}>
            View Courses
          </Button>
        </div>
      );
    } else {
      return <Fragment></Fragment>;
    }
  };

  return (
    <div id="NotFound">
      <div className="NotFound-aesthetic">
        <Icon type={IconTypes.Surprise} size="6x" />
        <div className="NotFound-aesthetic-content">
          <p className="NotFound-404">404</p>
          <p className="NotFound-message">Not Found</p>
        </div>
      </div>
      <p className="NotFound-descriptive">
        <strong>Uh Oh!</strong>&nbsp;{generateMessage()}
      </p>
      {renderNavigation()}
    </div>
  );
}

export default NotFoundContent;