import React from 'react';
import PropTypes from 'prop-types';
import Quote from './Quote';
import { ReactComponent as QuoteImage } from '../../../images/QuotationMarks.svg';

function ImageQuoteCard({ orientation, imageURL, text, name }) {
  return (
    <div className={`ImageQuoteCard ${orientation}-orientation`}>
      <div className="ImageQuoteCard-container">
        <div className="image">
          <img src={imageURL} alt={name} />
        </div>
        <div className="ImageQuoteCard-content">
          <div className="ImageQuoteCard-content-inner">
            <div className="icon left">
              <QuoteImage />
            </div>
            <Quote 
              align="left"
              displayIcon={false}
              text={text}
              name={name}
            />
            <div className="icon right">
              <QuoteImage />
            </div>
          </div>
        </div>
      </div>
      <div className="clear"></div>
    </div>
  );
}

ImageQuoteCard.defaultProps = {
  orientation: 'left' // can alternately supply 'right'
};

ImageQuoteCard.propTypes = {
  imageURL: PropTypes.string,
  text: PropTypes.string,
  name: PropTypes.string
};

export default ImageQuoteCard;