import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as QuoteImage } from '../../../images/QuotationMarks.svg';


function Quote({ className, align, displayIcon, text, name }) {
  return (
    <div className={`Quote ${className}`}>
      { displayIcon && 
        <div className="icon">
          <QuoteImage />
        </div>
      }
      <div className={`Quote-content align-${align}`}>
        <p className="text">
          {text}
        </p>
        { Boolean(name) &&
          <p className="name">
            – {name}
          </p>
        }
      </div>
    </div>
  );
}

Quote.defaultProps = {
  className: '',
  align: 'center', // can alternately supply 'left'
  displayIcon: true
};

Quote.propTypes = {
  text: PropTypes.string,
  name: PropTypes.string
};

export default Quote;