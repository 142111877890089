import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Icon, { IconTypes } from '../../common/Icon';
import StandardSystemPopup from '../../layout/popup/StandardSystemPopup';
import VideoEmbed from '../../common/media/VideoEmbed';

function VideoPopupCard({ imageURL, videoId, videoType, title, text }) {
  const [videoOpen, setVideoOpen] = useState(false);

  const toggleVideo = () => {
    setVideoOpen(!videoOpen);
  };

  return (
    <div className="VideoPopupCard">
      <div className="VideoPopupCard-container">
        <div className="VideoPopupCard-image" onClick={toggleVideo}>
          <div className="image-bg">
            <img src={imageURL} alt={title} />
          </div>
          <div className="overlay"></div>
          <div className="video-icon">
            <div className="icon-container">
              <Icon type={IconTypes.OnDemand} />
            </div>
          </div>
        </div>
      </div>
      <StandardSystemPopup 
        className="VideoPopupCard-popup"
        open={videoOpen} 
        title={title} 
        onClose={toggleVideo}
        actions={(
          <Button className="close" variant="text" onClick={toggleVideo}>
            Close
          </Button>
        )}
      >
        <div className="video-container">
          <VideoEmbed
            video={{
              id: videoId,
              serviceProvider: videoType,
              title
            }}
            onProgress={() => {}}
          />
          { Boolean(text) &&
            <p className="story-content">{text}</p>
          }
        </div>
      </StandardSystemPopup>
    </div>
  );
}

VideoPopupCard.propTypes = {
  imageURL: PropTypes.string, // for the static image of video
  videoId: PropTypes.string,  // for the video itself
  videoType: PropTypes.string,  // e.g., Vimeo, YouTube
  title: PropTypes.string,
  text: PropTypes.string
};

export default VideoPopupCard;