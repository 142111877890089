import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TitleWithSubtext from '../../common/TitleWithSubtext';
import PropTypes from 'prop-types';

function Pitch(props) {
  const { content } = props; 
  const { value: titleWithSubtext } = content['landingCommunity.donationIntro']; 
  const button = content['landingCommunity.donationButton'].value; 
  const navigate = useNavigate();

  const nav = URL => () => {
    navigate(URL);
  }

  return (
    <div className="Magdala-Landing-Pitch container">
      <div className="container-content">
        <TitleWithSubtext titleWithSubtext={titleWithSubtext} />
        <Button variant="outlined" onClick={nav(`/${button.url}`)}>{button.title}</Button>
      </div>
    </div>
  );
}

Pitch.propTypes = {
  content: PropTypes.object
}

export default Pitch;