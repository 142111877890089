import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from '../../../../utils/format';

function AnimatedNumber({ duration, startAnimation, formatted, dollars, percent, number, onUpdate }) {
  const [init, setInit] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [jump, setJump] = useState(0);
  const [displayNumber, setDisplayNumber] = useState(0);

  const updateNumber = useCallback(() => {
    if(number - displayNumber < jump) {
      setAnimating(false);
      setDisplayNumber(number);
      if(onUpdate) {
        onUpdate(number);
      }
      return;
    }

    if(!animating) {
      setAnimating(true);
    }

    let updatedNumber = displayNumber + jump;
    setDisplayNumber(updatedNumber);
    if(onUpdate) {
      onUpdate(updatedNumber);
    }
  }, [number, animating, jump, displayNumber, onUpdate]);

  useEffect(() => {
    if(!init && startAnimation) {
      setJump(number/duration);
      setTimeout(updateNumber, 1);
      setInit(true);
    }
  }, [init, number, startAnimation, duration, updateNumber]);

  useEffect(() => {
    if(animating) {
      setTimeout(updateNumber, 1);
    }
  }, [animating, displayNumber, updateNumber]);
  
  return (
    <span className="AnimatedNumber">
      {`${dollars? '$' : ''}${formatted? formatCurrency(parseInt(displayNumber)) : parseInt(displayNumber)}${percent? '%' : ''}`}
    </span>
  );
}

AnimatedNumber.defaultProps = {
  duration: 500,
  startAnimation: true,
  formatted: false,       // formats with commas
  dollars: false,         // true = adds dollar sign
  percent: false          // true = adds percent sign
};

AnimatedNumber.propTypes = {
  number: PropTypes.number,
  onUpdate: PropTypes.func
};

export default AnimatedNumber;