import React from 'react';
import PropTypes from 'prop-types';

function BasicCarouselCard({ id, imageURL, title, text, containerStyle }) {
  return (
    <div id={id} className="BasicCarouselCard">
      <div className="BasicCarouselCard-container" style={containerStyle}>
        <div className="image">
          <img src={imageURL} alt={title} />
        </div>
        <div className="details">
          <h5>{title}</h5>
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
}

BasicCarouselCard.propTypes = {
  containerStyle: {}
};

BasicCarouselCard.propTypes = {
  id: PropTypes.string,
  imageURL: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string
};

export default BasicCarouselCard;