import React, { Fragment, useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useAnalytics from '../../../hooks/useAnalytics';
import * as eventsAPI from '../../../actions/content/events';
import EventList from './display/EventList';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import config from '../../../config/config';
import urls from '../../../config/urls';
import stateConfig from '../../../config/state';
import { resetViewToTopOfPage } from '../../../utils/utils';
import validator from '../../../utils/validator';
import SEOMeta from '../../common/SEOMeta';
const { isEmpty, isNotEmpty } = validator;


function Events({ events, eventsAPI }) {
  const [loading, setLoading] = useState(true);
  const [eventList, setEventList] = useState(null);
  const [error, setError] = useState(null);
  useAnalytics('Live Events');
  const stateId = `${stateConfig.keys.EVENT_LIST}.events`;
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const eventData = events.byId[stateId];
    if(loading && isEmpty(eventList) && isNotEmpty(eventData) && events.ids) {
      if(eventData.error) {
        setError(eventData.error);
      } else {
        setEventList(eventData);
      }
      setLoading(false);
    }
  }, [loading, eventList, events.byId, events.ids, stateId]);

  initialize = () => {
    resetViewToTopOfPage();
    const eventsData = events.byId[stateId];
    if(isNotEmpty(eventsData) && !eventsData.error) {
      setEventList(eventsData);
      setLoading(false);
    } else {
      eventsAPI.getEvents();
    }
  };

  const clearPopMessage = () => {
    setError(null);
  };
  
  return (
    <div className="MainContent-container">
      { isEmpty(eventList) && !error && <LoadingMessage message="Loading..." /> }
      
      <div className="EventList-container current-and-upcoming">
        { error &&
          <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
            type={stateConfig.messageTypes.ERROR}>
            <p>We could not load the requested page.</p>
          </PopMessage>
        }

        { isNotEmpty(eventList) && 
          <Fragment>
            <SEOMeta 
              title="Live Events" 
              description="Current and Upcoming events including live webinars and pre-class discussions." />
            <EventList events={eventList.live} displayType={config.eventListTypes.CURRENT_AND_UPCOMING}
              heading="Current and Upcoming Events" moreText="Full Event/Curriculum Listing"
              moreLink={urls.curriculum} moreAtLimit={true} />
            <div className="spacer"></div>
            <EventList events={eventList.recent} displayType={config.eventListTypes.BROWSE}
              heading="Recent Events" cardsPerRender={config.defaultCardsPerRender} />
          </Fragment>
        }
      </div>
    </div>
  );
}


function mapStateToProps(state) {
  return { events: state.events };
}

function mapDispatchToProps(dispatch) {
  return { 
    eventsAPI: bindActionCreators(eventsAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Events);