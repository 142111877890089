import React from 'react';
import PropTypes from 'prop-types';
import AnimatedNumber from './AnimatedNumber';
import { formatCurrency } from '../../../../utils/format';
import validator from '../../../../utils/validator';
const { isNotEmpty } = validator;


function NumberComparison({ animated, duration, startAnimation, formatted, dollars, percent, title, topNumber, topDescription, bottomNumber, bottomDescription }) {
  return (
    <div className="NumberComparison">
      { isNotEmpty(title) &&
        <div className="NumberComparison-title">
          <span>{title}</span>
        </div>
      }
      <div className="NumberComparison-container">
        <div className="top-number number-container">
          <div className="number">
            { !animated &&
              <span>{`${dollars? '$' : ''}${formatted? formatCurrency(parseInt(topNumber)) : parseInt(topNumber)}${percent? '%' : ''}`}</span>
            }
            { animated &&
              <AnimatedNumber 
                duration={duration}
                startAnimation={startAnimation}
                formatted={formatted}
                dollars={dollars}
                percent={percent}
                number={topNumber}
              />
            }
          </div>
          <div className="description">{topDescription}</div>
        </div>
        <div className="comparison-divider">
          <div className="arrow-up"></div>
        </div>
        <div className="bottom-number number-container">
          <div className="number">
            <span>{`${dollars? '$' : ''}${formatted? formatCurrency(parseInt(bottomNumber)) : parseInt(bottomNumber)}${percent? '%' : ''}`}</span>
          </div>
          <div className="description">{bottomDescription}</div>
        </div>
      </div>
    </div>
  );
}

NumberComparison.defaultProps = {
  animated: false,
  duration: 500,
  startAnimation: true,
  formatted: false,       // formats with commas
  dollars: false,         // true = adds dollar sign
  percent: false          // true = adds percent sign
};

NumberComparison.propTypes = {
  title: PropTypes.string,
  topNumber: PropTypes.number,
  topDescription: PropTypes.string,
  bottomNumber: PropTypes.number,
  bottomDescription: PropTypes.string
};

export default NumberComparison;