import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import querystring from 'qs';
import * as postsAPI from '../../../../actions/content/posts';
import stateConfig from '../../../../config/state';
import config from '../../../../config/config';


function DonateHeading({ posts, postsAPI }) {
  const [campaign, setCampaign] = useState('');
  const [snippet, setSnippet] = useState(null);
  const location = useLocation();
  // defining fns used in useEffect blocks
  let loadCampaignSnippet = config.emptyFn;

  useEffect(() => {
    loadCampaignSnippet();   
  }, [location, loadCampaignSnippet]);

  useEffect(() => {
    if(snippet === null) {
      const slug = determineSlug(campaign);
      const snippet = posts.byId[`${stateConfig.keys.SNIPPET}.${slug}`];
      if(snippet !== null && snippet !== undefined) {
        setSnippet({ content: snippet.content || null });
      }
    }
  }, [posts.id, posts.byId, snippet, campaign]);

  loadCampaignSnippet = () => {
    setSnippet(null); 
    const params = querystring.parse(location.search.substring(1));
    const slug = determineSlug(params.campaign || '');
    postsAPI.getSnippetBySlug(slug);
    setCampaign(params.campaign || '');
  };

  const determineSlug = (code) => {
    return code === ''? 'donation-default' : `donation-${code.toLowerCase()}`;
  };

  return (
    <div className="heading">
      { snippet !== null && snippet.content === null &&
        <Fragment>
          <h1>Support the Institute</h1>
          <p>
            "Give, and it shall be given to you; good measure, pressed down, shaken together, running over, 
            shall be put into your lap. For with the same measure that you shall give, it shall be measured 
            to you again" (Luke 6:38).
          </p>
        </Fragment>
      }
      { snippet !== null && snippet.content !== null &&
        <div className="snippet-content" dangerouslySetInnerHTML={{__html: snippet.content}} />
      }
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    posts: state.posts 
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    postsAPI: bindActionCreators(postsAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DonateHeading);