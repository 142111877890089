import React from 'react';
import PropTypes from 'prop-types';
import Section from '../Section';
import VideoPopupCard from '../../../common/VideoPopupCard';
//import urls from '../../../../../config/urls';


function HeadingSection({ content }) {
  const { value: video } = content['heading.video'];

  return (
    <Section className="heading">
      {/* Removed per Father/Kelsey, but leaving code in case they change their minds
      <div className="branding" onClick={() => window.open(urls.root, '_blank')}>
        <img src="https://instituteofcatholicculture.net/wp-content/uploads/2022/11/icc-branding-simple.png" alt="Institute of Catholic Culture" />
      </div>
      */}
      <h1 className="title">
        <div className="line">
        <span className="text">our</span>
        <span className="emphasis">year</span>
        </div>
        <div className="line">
        <span className="text">in</span>
        <span className="emphasis">review</span>
        </div>
      </h1>
      <div className="subsection large-display">
        <div className="col1 welcome-video">
          <VideoPopupCard 
            imageURL={video.image}
            videoId={video.videoId}
            videoType={video.videoProvider}
            title={video.title}
          />
        </div>
        <div className="col2">
          <h2 className="subtitle">
            Annual Report <span className="emphasis">2022</span>
          </h2>
          <div className="logo-branding">
            <img src="https://instituteofcatholicculture.net/wp-content/uploads/2022/11/icc-logo-2022-edited.png" alt="Institute of Catholic Culture Logo" />
          </div>
        </div>
      </div>
      <div className="subsection small-display">
        <h2 className="subtitle">
          Annual Report <span className="emphasis">2022</span>
        </h2>
        <div className="welcome-video">
          <VideoPopupCard 
            imageURL={video.image}
            videoId={video.videoId}
            videoType={video.videoProvider}
            title={video.title}
          />
        </div>
      </div>
    </Section>
  );
};

HeadingSection.propTypes = {
  content: PropTypes.object
};

export default HeadingSection;