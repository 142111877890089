import React, { useState } from 'react';
import PropTypes from 'prop-types';


export function StoryCard({ selected, children }) {
  return (
    <div className={`StoryCard ${selected? 'show' : 'hide'}`}>
      {children}
    </div>
  );
}

StoryCard.defaultProps = {
  selected: false
};


function StoryNavigator({ className, navItems, children }) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div className={`StoryNavigator ${className}`}>
      <div className="StoryNavigator-nav">
        {
          navItems.map((item, index) => {
            return (
              <div 
                key={`nav-item-${index}`} 
                className={`nav-item ${index === selectedIndex? 'active' : 'not-active'}`}
                onClick={() => setSelectedIndex(index)}
              >
                <img src={item.imageURL} alt={item.tite} />
                <div className="title">
                  <p>{item.title}</p>
                </div>
              </div>
            );
          })
        }
      </div>
      <div className="StoryNavigator-cards">
        <h5>{navItems[selectedIndex].title}</h5>
        {
          children.map((child, index) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { selected: (index === selectedIndex) });
            }
            return child;
          })
        }
      </div>
    </div>
  );
}

StoryNavigator.defaultProps = {
  className: ''
};

StoryNavigator.propTypes = {
  navItems: PropTypes.array
};

export default StoryNavigator;