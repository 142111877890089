import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import useRefDimensions from '../../../../hooks/useRefDimensions';
import urls from '../../../../config/urls';
const topojson = window.topojson;

const mapPinIcon = 'https://instituteofcatholicculture.net/wp-content/uploads/2022/11/map-pin.png';


function GeoMap({ className, title, locations, highlights, onSelection }) {
  let containerRef = useRef(null);
  let containerDimensions = useRefDimensions(containerRef);
  const mapRef = useRef(null);
  const [init, setInit] = useState(false);
  const [initDraw, setInitDraw] = useState(false);
  const [geoData, setGeoData] = useState(null);

  const drawMap = useCallback(() => {
    let mapEl = mapRef.current;
    let width = containerDimensions.width < 900? containerDimensions.width : 900
    let height = parseInt(width / 1.5);

    // configure the map projection
    const projection = d3.geoMercator()
      .scale(140)
      .translate([width/2, height/1.4]);
    const path = d3.geoPath(projection);

    // draw the map
    d3.select(mapEl)
      .append('g')
      .selectAll('path')
      .data(geoData.features)   // pass in geodata
      .enter()
      .append('path')
      .attr('class', (d) => {   // if the country is in the highlight list, set it to fill, use attr in css
        const color = highlights.includes(d.properties.name)? 'fill' : 'default';
        return `country color-${color}`;
      })
      .attr('d', path);         // pass in projection config
    
    if(locations !== null) {
      d3.select(mapEl)
        .selectAll(".m")
        .data(locations)          // an array of objs, w/long. & lat.
        .enter()
        .append("image")          // build icon element for map pin
        .attr('class', 'map-pin') 
        .attr('width', 31)
        .attr('height', 50)
        .attr("xlink:href", mapPinIcon)
        .attr("transform", (d) => {
          let p = projection([d.long,d.lat]);
            return `translate(${p[0]-15}, ${p[1]-50})`; // adjust position to center pin on location
        })
        .on("click", function (d) {  // handle click event
          if(Boolean(onSelection)) {
            onSelection(d.target.__data__);
          }
        });
    }
    
  }, [geoData, locations, highlights, onSelection, containerDimensions.width]);

  useEffect(() => {
    if(!init) {
      d3.json(urls.hubAPITopoData)
        .then(topodata => {
          const countries = topojson.feature(topodata, topodata.objects.countries);
          setGeoData(countries);
        });
      setInit(true);
    }
  }, [init]);

  useEffect(() => {
    if(init && Boolean(geoData) && !initDraw) {
      setInitDraw(true);
      drawMap();
    }
  }, [init, initDraw, geoData, drawMap]);

  return (
    <div className={`GeoMap ${className}`}>
      { Boolean(title) && 
        <h4>{title}</h4>
      }
      <div ref={containerRef} className="GeoMap-container">
        <svg 
          ref={mapRef} 
          className="GeoMap-svg">
        </svg>
      </div>
    </div>
  );
}

GeoMap.defaultProps = {
  className: '',
  locations: null,    // selectable locations
  highlights: []      // countries that should be filled/highlighted
};

GeoMap.propTypes = {
  title: PropTypes.string,
  onSelection: PropTypes.func
};

export default GeoMap;