import React from 'react';
import PropTypes from 'prop-types';

function NavigationMenu({ content, onNavigate }) {
  const navKeys = Object.keys(content).filter(item => item.includes('welcome.navigation'));

  return (
    <div className="AnnualReport-navigation">
      <h3>What's Inside:</h3>
      <ul className="navigation-menu">
        <li className="dark-blue" onClick={() => onNavigate(0)}>
          <span className="bar"></span> {content[navKeys[0]].value}
        </li>
        <li className="green" onClick={() => onNavigate(1)}>
          <span className="bar"></span> {content[navKeys[1]].value}
        </li>
        <li className="gold" onClick={() => onNavigate(2)}>
          <span className="bar"></span> {content[navKeys[2]].value}
        </li>
        <li className="gray" onClick={() => onNavigate(3)}>
          <span className="bar"></span> {content[navKeys[3]].value}
        </li>
        <li className="light-blue" onClick={() => onNavigate(4)}>
          <span className="bar"></span> {content[navKeys[4]].value}
        </li>
        <li className="red" onClick={() => onNavigate(5)}>
          <span className="bar"></span> {content[navKeys[5]].value}
        </li>
        <li className="tan" onClick={() => onNavigate(6)}>
          <span className="bar"></span> {content[navKeys[6]].value}
        </li>
        <li className="dark-blue" onClick={() => onNavigate(7)}>
          <span className="bar"></span> {content[navKeys[7]].value}
        </li>
      </ul>
    </div>
  );
}

NavigationMenu.propTypes = {
  content: PropTypes.object,
  onNavigate: PropTypes.func
};

export default NavigationMenu

