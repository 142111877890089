import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import validator from '../../../utils/validator';
const { isEmpty, isNotEmpty } = validator;

const DEFAULT_TRANSFORM = 'translate(0px, 0px)';

function ScrollingBackground({ divisor, percentVisible, position, children }) {
  const compRef = useRef(null);
  const [startPosition, setStartPosition] = useState(0);
  const [calcStyle, setCalcStyle] = useState(null);
  const [transform, setTransform] = useState(DEFAULT_TRANSFORM);

  const handleAnimation = useCallback(pos => {
    // calculate where top and bottom boundaries of component are
    const el = compRef.current;
    const rect = el.getBoundingClientRect();
    const passedTop = rect.top < window.innerHeight;
    const passedBottom = rect.bottom < 0;

    /*
    Possible situations:
    #1 The component is further down the page we have not and don't want to start animation yet (do nothing)
    #2 The component is in the viewport and we want the animation to be active
    #3 We have scrolled past the component and it is no longer in the viewport, we want to freeze the animation (do nothing)
    #4 We have scrolled up past the component and it is no longer in the viewport, we want to reset the animation to default
    #5 The page has loaded and we are below the scroll list, we want to calculate our position once we scroll up to the bottom edge
    #6 The page has loaded and the scroll list is already in view, we need to calculate
    */

    // determine the calc style to use if not already done
    if(!Boolean(calcStyle) && pos > 0) {
      if(passedTop && passedBottom) { // started below, situation #5, see above
        setCalcStyle('bottom-up');
      } else if(passedTop && !passedBottom) { // started visible, situation #6, see above
        setCalcStyle('began-visible');
      } else {
        // started above, normal condition
        setCalcStyle('normal');
      }
    }

    const animationActive = passedTop && !passedBottom; // Situation #2, see above
    const resetAnimation = !passedTop && !passedBottom && transform !== DEFAULT_TRANSFORM; // Situation #4, see above
    
    let startPos = startPosition;
    if(calcStyle === 'normal' && animationActive && startPos === 0) {
      // we are about to start our animation, set starting position of animation for scroll adj. calc
      startPos = pos-1;
      setStartPosition(startPos);
    } else if(calcStyle === 'began-visible' && animationActive && startPos === 0) {
      startPos = pos - (rect.height * (percentVisible / 100));
      setStartPosition(startPos);
    } else if(calcStyle === 'bottom-up' && animationActive && startPos === 0) {
      startPos = pos - (2 * (rect.height * 1.25)); // try to roughly calculate the start position of the container
      setStartPosition(startPos);
    }
    
    if(Boolean(calcStyle) && animationActive) {
      const adjustment = (pos-startPos)/divisor;
      setTransform(`translate(0%, -${adjustment}%)`);
    } else if(resetAnimation) {
      setTransform(DEFAULT_TRANSFORM);
      setStartPosition(0);
      setCalcStyle('normal'); // when resetting, also shift to normal calc if not already using it
    }
  }, [divisor, percentVisible, transform, startPosition, calcStyle]);

  const handleScroll = useCallback(() => {
    setTimeout(() => handleAnimation(window.pageYOffset), 500);
  }, [handleAnimation]);

  useEffect(() => {
    if(isNotEmpty(position)) { // we are being passed scroll position, use it
      handleAnimation(position);
    }
  }, [position, handleAnimation]);

  useEffect(() => {
    if(isEmpty(position)) { // we are not being passed scroll position, add a listener
      window.addEventListener('scroll', handleScroll, { passive: true });
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [position, handleScroll]);

  return (
    <div ref={compRef} className="ScrollingBackground">
      <div className="ScrollingBackground-container">
        <div className="ScrollingBackground-content" style={{ transform }}>
          {children}
        </div>
      </div>
    </div>
  );
}

ScrollingBackground.defaultProps = {
  divisor: 15, // lower = faster scrolling speed, higher = slower
  percentVisible: 50 // this value is only used if it is possible for the list to appear visible on load
};

ScrollingBackground.propTypes = {
  position: PropTypes.number // can provide if container is already tracking scroll pos
};

export default ScrollingBackground;