import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Section from '../Section';
import Quote from '../../../common/Quote';
import LazyLoad from '../../../../layout/LazyLoad';


function ImageQuoteInterstitial({ className, orientation, buttonVariant, imageURL, text, name, buttonTitle, onClick, scrollPos }) {
  return (
    <Section className={`image-quote interstitial ${className} ${orientation}`}>
      <LazyLoad className="container" position={scrollPos}>
        { orientation === 'left' &&
          <div className="col1 large-display">
            <img src={imageURL} alt="Quote Feature" />
            { Boolean(buttonTitle) && Boolean(onClick) &&
              <Button className="action" variant={buttonVariant} onClick={onClick}>
                {buttonTitle}
              </Button>
            }
          </div>
        }
        <Quote 
          className="large-display"
          align="left"
          text={text}
          name={name}
        />
        { orientation === 'right' &&
          <div className="col2 large-display">
            <img src={imageURL} alt="Quote Feature" />
            { Boolean(buttonTitle) && Boolean(onClick) &&
              <Button className="action" variant={buttonVariant} onClick={onClick}>
                {buttonTitle}
              </Button>
            }
          </div>
        }
        <div className="small-display">
          <div className="row1">
            <img src={imageURL} alt="Quote Feature" />
          </div>
          <Quote 
            align="left"
            text={text}
            name={name}
          />
          { Boolean(buttonTitle) && Boolean(onClick) &&
            <Button className="action" variant={buttonVariant} onClick={onClick}>
              {buttonTitle}
            </Button>
          }
        </div>
      </LazyLoad>
    </Section>
  );
};

ImageQuoteInterstitial.defaultProps = {
  className: '',
  orientation: 'left',        // alt. 'right'
  buttonVariant: 'outlined'
}

ImageQuoteInterstitial.propTypes = {
  imageURL: PropTypes.string,
  text: PropTypes.string,
  name: PropTypes.string,
  buttonTitle: PropTypes.string,
  onClick: PropTypes.func,
  scrollPos: PropTypes.number
};

export default ImageQuoteInterstitial;