import React from 'react';
import PropTypes from 'prop-types';
import AnimatedNumber from './AnimatedNumber';
import { formatCurrency } from '../../../../utils/format';

function NumericFigure({ animated, duration, startAnimation, formatted, dollars, percent, number, description, backgroundImage }) {
  return (
    <div className="NumericFigure">
      <div className={`NumericFigure-container image-${Boolean(backgroundImage)? 'bg' : 'none'}`} 
        style={{ backgroundImage: Boolean(backgroundImage)? `url(${backgroundImage})` : 'none' }}>
        <div className="NumericFigure-inner">
          <div className="number">
            { !animated &&
              <span>{`${dollars? '$' : ''}${formatted? formatCurrency(parseInt(number)) : parseInt(number)}${percent? '%' : ''}`}</span>
            }
            { animated &&
              <AnimatedNumber 
                duration={duration}
                startAnimation={startAnimation}
                formatted={formatted}
                dollars={dollars}
                percent={percent}
                number={number}
              />
            }
          </div>
          <div className="description">{description}</div>
        </div>
      </div>
    </div>
  );
}

NumericFigure.defaultProps = {
  animated: false,
  duration: 500,
  startAnimation: true,
  formatted: false,       // formats with commas
  dollars: false,         // true = adds dollar sign
  percent: false          // true = adds percent sign
};

NumericFigure.propTypes = {
  number: PropTypes.number,
  description: PropTypes.string,
  backgroundImage: PropTypes.string // URL
};

export default NumericFigure;