import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';


function LazyLoad({ children, className, id, position, offset, onLoad }) {
  const compRef = useRef(null);
  const [loaded, setLoaded] = useState(false);

  const handleAnimation = useCallback(pos => {
    // calculate where top and bottom boundaries of component are
    const el = compRef.current;
    const rect = el.getBoundingClientRect();
    const passedTop = (rect.top + offset) < window.innerHeight;
    
    if(passedTop) {
      setLoaded(true);
      if(Boolean(onLoad)) {
        setTimeout(() => onLoad(id), 500);
      }
    }

  }, [id, offset, onLoad]);

  useEffect(() => {
    if(!loaded && Boolean(position)) { 
      handleAnimation(position);
    }
  }, [loaded, position, handleAnimation]);

  return (
    <div ref={compRef} className={`LazyLoad ${className} ${loaded? 'visible' : 'hidden'}`}>
      {children}
    </div>
  );
}

LazyLoad.defaultProps = {
  className: '',
  offset: 100
};

LazyLoad.propTypes = {
  id: PropTypes.string,
  position: PropTypes.number, // scroll position
  onLoad: PropTypes.func
};

export default LazyLoad;