import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import StickyBottomMenu from '../../../layout/menus/StickyBottomMenu';

function BottomNavigation({ display, content, onNavigate }) {
  const navKeys = Object.keys(content).filter(item => item.includes('welcome.navigation'));

  return (
    <Fragment>
      <StickyBottomMenu 
        className={`AnnualReport-bottom-nav ${display? 'visible' : 'hidden'}`}
        iconOnly={false}
        menuTitle="Menu"
        menuItems={[
          { className: "dark-blue", content: (<div><span className="bar"></span> {content[navKeys[0]].value}</div>) },
          { className: "green", content: (<div><span className="bar"></span> {content[navKeys[1]].value}</div>) },
          { className: "gold", content: (<div><span className="bar"></span> {content[navKeys[2]].value}</div>) },
          { className: "gray", content: (<div><span className="bar"></span> {content[navKeys[3]].value}</div>) },
          { className: "light-blue", content: (<div><span className="bar"></span> {content[navKeys[4]].value}</div>) },
          { className: "red", content: (<div><span className="bar"></span> {content[navKeys[5]].value}</div>) },
          { className: "tan", content: (<div><span className="bar"></span> {content[navKeys[6]].value}</div>) },
          { className: "dark-blue", content: (<div><span className="bar"></span> {content[navKeys[7]].value}</div>) },
          { className: "gold", content: (<div><span className="bar"></span> Support Our Work</div>) }
        ]}
        onNavigate={onNavigate} 
      />
    </Fragment>
  );
}

BottomNavigation.defaultProps = {
  display: false
};

BottomNavigation.propTypes = {
  content: PropTypes.object,
  onNavigate: PropTypes.func
};

export default BottomNavigation

