import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Icon, { IconTypes } from '../../common/Icon';

function StickyBottomMenu({ className, menuTitle, menuItems, iconOnly, onNavigate }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleNavigation = index => () => {
    setAnchorEl(null);
    onNavigate(index);
  };

  const icon = Boolean(anchorEl)? <Icon type={IconTypes.ClosePlain} /> : <Icon type={IconTypes.Add} />;

  return (
    <div className={`StickyBottomMenu ${className}`}>
      <div className="StickyBottomMenu-layout-container">
        <div className="StickyBottomMenu-container">
          <div className="menu-content">
            <Menu
              id="StickyBottomMenu-menu"
              className={`StickyBottomMenu-menu-${className}`}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              {
                menuItems.map((item, index) => {
                  const { className, content } = item;
                  return (
                    <MenuItem key={`sbmi-${index}`} className={className} onClick={handleNavigation(index)}>
                      {content}
                    </MenuItem>
                  );
                })
              }
            </Menu>
          </div>
          <div className={`menu-anchor ${iconOnly? 'icon-only' : 'full-button'}`}>
            { !iconOnly &&
              <Button 
                id="StickyBottomMenu-button" 
                className={`StickyBottomMenu-button-${className}`} 
                onClick={event => setAnchorEl(Boolean(anchorEl)? null : event.target)}
              >
                <div className="button-text">{menuTitle}{icon}</div>
              </Button>
            }
            <IconButton 
              id="StickyBottomMenu-icon-button"
              className={`StickyBottomMenu-button-${className}`} 
              onClick={event => setAnchorEl(Boolean(anchorEl)? null : event.target)}
            >
              <div className="icon-container">{icon}</div>
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}

StickyBottomMenu.defaultProps = {
  iconOnly: true
};

StickyBottomMenu.propTypes = {
  className: PropTypes.string,
  menuTitle: PropTypes.string,
  menuItems: PropTypes.array,
  onNavigate: PropTypes.func
};

export default StickyBottomMenu;