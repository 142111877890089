import React from 'react';
import { useNavigate } from 'react-router-dom';
import urls from '../../../config/urls';
import TitleWithSubtext from '../../common/TitleWithSubtext';
import PropTypes from 'prop-types';

function Cards(props) {
	const content = props.content; 
	const { value: titleWithSubtext } = content['program.intro']; 
	const card1 = content['program.smallCard1'].value; 
	const card2 = content['program.smallCard2'].value;
	const card3 = content['program.smallCard3'].value; 
	const navigate = useNavigate();

	const nav = URL => () => {
		navigate(URL);
	};

	return (
		<div className="program-cards-intro">
			<TitleWithSubtext titleWithSubtext={titleWithSubtext} />
			<div className="Magdala-Program-Cards">
				<div className="culture container">
					<div className="container-content">
						<div className="cards">
							<div className="card" onClick={nav(urls.magdalaRegistration)}>
								<h4 className="title">Step 1</h4>
								<div className="feature-image" style={{backgroundImage: `url(${card1.image})`}}></div>
								<h4 className="label">{card1.title}</h4>
							</div>
							<div className="card" onClick={nav(urls.magdalaCurriculumPage)}>
								<h4 className="title">Step 2</h4>
								<div className="feature-image" style={{backgroundImage: `url(${card2.image})`}}></div>
								<h4 className="label">{card2.title}</h4>
							</div>
							<div className="card" onClick={nav(urls.magdalaCoursesPage)}>
								<h4 className="title">Step 3</h4>
								<div className="feature-image" style={{backgroundImage: `url(${card3.image})`}}></div>
								<h4 className="label">{card3.title}</h4>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

Cards.propTypes = {
	content: PropTypes.object
}

export default Cards; 