import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Icon, { IconTypes } from '../../common/Icon';

function ImageStoryCard({ imageURL, altImageURL, title, subtitle, text }) {
  const [storyOpen, setStoryOpen] = useState(false);

  const toggleCard = () => {
    setStoryOpen(!storyOpen);
  };

  const icon = storyOpen? <Icon type={IconTypes.ClosePlain} /> : <Icon type={IconTypes.Add} />;

  return (
    <div className="ImageStoryCard" onClick={toggleCard}>
      <div className={`ImageStoryCard-container story-${storyOpen? 'open' : 'closed'}`}>
        <img className="ImageStoryCard-image" src={imageURL} alt={title} />
        <img className="ImageStoryCard-image-alt" src={altImageURL} alt={title} />
        <div className="ImageStoryCard-overlay">
          <div className="initial-content">
            <div className="title">
              <h4>{title}</h4>
              <h5>{subtitle}</h5>
            </div>
          </div>
          <div className="ImageStoryCard-story">
            <div className="ImageStoryCard-story-container">
              <h4>{title}</h4>
              <h5>{subtitle}</h5>
              <p>{text}</p>
            </div>
          </div>
          <div className="ImageStoryCard-button">
            <IconButton className="nav-button" onClick={toggleCard}>
              <div className="icon-container">{icon}</div>
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}

ImageStoryCard.propTypes = {
  imageURL: PropTypes.string,
  altImageURL: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string
};

export default ImageStoryCard;