import React from 'react';
import PropTypes from 'prop-types';
import Section from '../Section';
import NavigationMenu from '../NavigationMenu';

function WelcomeSection({ content, onNavigate }) {
  const { value: welcomeText } = content['welcome.text'];
  const { value: welcomeTextMobile } = content['welcome.text.mobile'];

  return (
    <Section className="welcome">
      <div className="welcome-text">
        <p className="salutation">Dear Friends and Benefactors,</p>
        <div className="welcome-text-content large-display" dangerouslySetInnerHTML={{__html: welcomeText}}></div>
        <div className="welcome-text-content small-display" dangerouslySetInnerHTML={{__html: welcomeTextMobile}}></div>
        <p className="name">Fr. Hezekias Carnazzo</p>
        <p className="title">Founder & Executive Director</p>
      </div>
      <NavigationMenu content={content} onNavigate={onNavigate} />
    </Section>
  );
};

WelcomeSection.propTypes = {
  content: PropTypes.object,
  onNavigate: PropTypes.func
};

export default WelcomeSection;