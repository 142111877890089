import React, { useState, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import DonateForm from '../../../../member/donate/DonateForm';
import DonateSuccess from '../../../../member/donate/DonateSuccess';
import Section from '../Section';
import SectionHeader from '../SectionHeader';
import NumericFigure from '../../../common/data/NumericFigure';
import PieChart from '../../../common/data/PieChart';
import ImageQuoteCard from '../../../common/ImageQuoteCard';
import LazyLoad from '../../../../layout/LazyLoad';


const stripeInitPromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function FinancesSection({ content, scrollPos }) {
  const [animations, setAnimations] = useState([]);
  const [donationComplete, setDonationComplete] = useState(false);
  const { value: heading } = content['finances.heading'];
  const { value: subHeading1 } = content['finances.subHeading1'];
  const { value: subHeading2 } = content['finances.subHeading2'];
  const { value: subHeading3 } = content['finances.subHeading3'];
  const { value: subText2 } = content['finances.subText2'];
  const { value: pieChartHeading } = content['finances.pieChartHeading'];
  const { value: number1 } = content['finances.number1'];
  const { value: number2 } = content['finances.number2'];
  const { value: number3 } = content['finances.number3'];
  const { value: pieChart } = content['finances.pieChart'];
  const { value: quote } = content['finances.quote'];
  const { value: accordion } = content['finances.accordion'];
  const { value: button } = content['finances.button'];
  const { value: sgHeading } = content['finances.specialGift.heading'];
  const { value: sgAmount1 } = content['finances.specialGift1.amount'];
  const { value: sgDesc1 } = content['finances.specialGift1.description'];
  const { value: sgAmount2 } = content['finances.specialGift2.amount'];
  const { value: sgDesc2 } = content['finances.specialGift2.description'];
  const { value: sgAmount3 } = content['finances.specialGift3.amount'];
  const { value: sgDesc3 } = content['finances.specialGift3.description'];
  const { value: sgAmount4 } = content['finances.specialGift4.amount'];
  const { value: sgDesc4 } = content['finances.specialGift4.description'];
  const { value: sgAmount5 } = content['finances.specialGift5.amount'];
  const { value: sgDesc5 } = content['finances.specialGift5.description'];

  const onAnimate = useCallback(key => {
    setAnimations([ ...animations, key ]);
  }, [animations]);

  const onDonationComplete = () => {
    setDonationComplete(true);
  };

  return (
    <Fragment>
      <Section className="finances top">
        <SectionHeader 
          heading={heading.title}
          subtext={heading.text}
        />
        <h4 className="sub-head1">{subHeading1}</h4>
        <LazyLoad
          id="stat-cards" 
          className="stat-cards-lazy" 
          position={scrollPos} 
          onLoad={onAnimate}
        >
          <div className="stat-cards">
            <NumericFigure 
              number={parseInt(number1.number)}
              description={number1.description}
              backgroundImage={number1.image}
              animated={number1.animated}
              formatted={true}
              startAnimation={animations.includes('stat-cards')}
            />
            <NumericFigure 
              number={parseInt(number2.number)}
              description={number2.description}
              backgroundImage={number2.image}
              animated={number2.animated}
              formatted={true}
              startAnimation={animations.includes('stat-cards')}
            />
            <NumericFigure 
              number={parseInt(number3.number)}
              description={number3.description}
              backgroundImage={number3.image}
              animated={number3.animated}
              formatted={true}
              startAnimation={animations.includes('stat-cards')}
            />
          </div>
        </LazyLoad>
      </Section>
      <Section className="finances bottom">
        <LazyLoad
          id="pie-chart" 
          className="pie-chart-lazy" 
          position={scrollPos} 
          onLoad={onAnimate}
        >
          <h4 className="sub-head1">{pieChartHeading}</h4>
          <PieChart 
            data={pieChart.map(item => parseInt(item.percentage))}
            labels={pieChart.map(item => item.label)}
            startAnimation={animations.includes('pie-chart')}
          />
          <ImageQuoteCard 
            orientation="left"
            imageURL={quote.image}
            text={quote.text}
            name={quote.name}
          />
        </LazyLoad>
        <Element name="donate-scroll"></Element>
        <LazyLoad className="donate-lazy" position={scrollPos}>
          <h4 className="sub-head2">{subHeading2}</h4>
          <p className="sub-head2-desc">{subText2}</p>
          <h4 className="sub-head-special-gift">{sgHeading}</h4>
          <div className="special-gift-opportunities">
            <div className="special-gift col1">
              <div className="amount">{sgAmount1}</div>
              <div className="description">{sgDesc1}</div>
            </div>
            <div className="special-gift col2">
              <div className="amount">{sgAmount2}</div>
              <div className="description">{sgDesc2}</div>
            </div>
            <div className="special-gift col3">
              <div className="amount">{sgAmount3}</div>
              <div className="description">{sgDesc3}</div>
            </div>
            <div className="special-gift col4">
              <div className="amount">{sgAmount4}</div>
              <div className="description">{sgDesc4}</div>
            </div>
            <div className="special-gift col5">
              <div className="amount">{sgAmount5}</div>
              <div className="description">{sgDesc5}</div>
            </div>
          </div>
          <div className="Donate-Embed">
            { !donationComplete &&
              <Elements stripe={stripeInitPromise}>
                <DonateForm 
                  onComplete={onDonationComplete} 
                  submitLabel={button.title} 
                  campaignCode="annualreport2022"
                  addressRequired={false}
                />
              </Elements>
            }
            { donationComplete &&
              <div className="DonateSuccess-Embed">
                <DonateSuccess messageOnly={true} />
              </div>
            }
          </div>
          <h4 className="sub-head3">{subHeading3}</h4>
          <div className="accordion-container">
            {
              accordion.map((item, index) => {
                return (
                  <Accordion key={`finances-accordion-${index}`}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      {item.title}
                    </AccordionSummary>
                    <AccordionDetails>
                      <div dangerouslySetInnerHTML={{__html: item.content }} />
                    </AccordionDetails>
                  </Accordion>
                );
              })
            }
          </div>
        </LazyLoad>
      </Section>
    </Fragment>
  );
};

FinancesSection.propTypes = {
  content: PropTypes.object,
  scrollPos: PropTypes.number
};

export default FinancesSection;