import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as progressAPI from '../../../../actions/progress';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Icon, { IconTypes } from '../../../common/Icon';
import urls from '../../../../config/urls';
import stateConfig from '../../../../config/state';
import config from '../../../../config/config';
import { listCourseParts, listAccessibleCourseParts, isPartOpen } from '../../../../utils/course';
import { slugify } from '../../../../utils/utils';
import validator from '../../../../utils/validator';
const { isEmpty } = validator;


function CourseOutline({ course, transcript, authenticated, registered, courseComplete, currentLessonId, selectedPartId, courseHome, updated, courseProgress, progressAPI }) {
  const [loaded, setLoaded] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [progress, setProgress] = useState(null);
  const [accessible, setAccessible] = useState([]);
  const [lessonId, setLessonId] = useState(null);
  const [openLesson, setOpenLesson] = useState(null);
  const { slug, lessons, exams } = course;
  const navigate = useNavigate();
  const location = useLocation();
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    setExpanded(false);
  }, [location]);

  useEffect(() => {
    if(currentLessonId !== lessonId) {
      setLessonId(currentLessonId);
      setOpenLesson(null);
    }
  }, [lessonId, currentLessonId]);

  useEffect(() => {
    const stateId = `${stateConfig.keys.PROGRESS}.${course.id}`;
    if(!loaded && isEmpty(progress) && courseProgress.ids.includes(stateId)) {
      const data = courseProgress.byId[stateId];
      if(data && !data.error) {
        const progressData = data && !data.error? data.progress : [];
        const isLinear = course.progression === 'linear';
        const parts = listCourseParts(course, transcript.track);
        const aParts = listAccessibleCourseParts(parts, progressData, isLinear);
        setAccessible(aParts);
        setProgress(progressData);
        setLoaded(true);
      }
    }
  }, [loaded, progress, courseProgress.ids, courseProgress.byId, course, transcript]);

  useEffect(() => {
    if(authenticated && registered) {
      setLoaded(false);
      setProgress(null);
    } else {
      setProgress([]);
      setLoaded(true);
    }
  }, [updated, authenticated, registered]);

  initialize = () => {
    if(authenticated && registered) {
      progressAPI.getCourseProgress(course.id);
    } else {
      setProgress([]);
      setLoaded(true);
    }
    setLessonId(currentLessonId);
  };

  const expandAll = () => {
    setOpenLesson(null);
    setExpanded(!expanded);
  };

  const expandLesson = lessonId => () => {
    if(currentLessonId === lessonId) return;

    if(expanded) {
      setExpanded(false);
    }

    if(openLesson === lessonId) {
      setOpenLesson(null);
    } else {
      setOpenLesson(lessonId);
    }
  };

  const renderLesson = lesson => {
    let title = lesson.title || '';
    title = title.split(':');
    let started = false, 
        complete = courseComplete, 
        statuses = [];
    
    if(!complete && lesson.parts && lesson.parts.length > 0) {
      statuses = lesson.parts.map(part => {
        const notRequired = (part.type !== 'assessment' && part.required === false);
        const notApplicable = (part.track !== 'cert-audit' && transcript.track !== part.track);
        if(notRequired || notApplicable) {
          return 'ignore';
        }

        let match = progress.find(item => item.partId === part.id);
        if(match) {
          return match.status;
        }
        return '';
      });

      started = (statuses.includes('started') || statuses.includes('complete'));
      complete = statuses.map(status => (status === 'complete' || status === 'ignore'));
      complete = !complete.includes(false);
    }

    return (
      <Fragment>
        <div className="lesson-heading">
          { !started && !complete && <Icon type={IconTypes.NotStarted} /> }
          { started && !complete && <Icon type={IconTypes.Incomplete} /> }
          { complete && <Icon type={IconTypes.CompleteFilled} /> }
          <h4 onClick={expandLesson(lesson.id)}>
            <span className="title1">{`${title[0]}${title.length > 1? ': ' : ''}`}</span>
            { title.length > 1 && <span className="title2">{title[1].trim()}</span> }
            { title.length > 2 && <span className="title3">: {title[2].trim()}</span> }
          </h4>
        </div>
        <Collapse className="parts" in={expanded || currentLessonId === lesson.id || openLesson === lesson.id}>
          { lesson.parts && lesson.parts.length > 0 &&
            lesson.parts.map(part => {
              return (
                <div key={part.id} className="part">
                  {renderPart(part)}
                </div>
              );
            })
          }
        </Collapse>
      </Fragment>
    );
  };

  const renderPart = part => {
    if(part.track !== 'cert-audit' && transcript.track !== part.track && !isEmpty(transcript.track)) {
      // this part doesn't pertain to the track the student is registered in
      return (<Fragment></Fragment>);
    }

    let url = part.type === 'topic'? 
      urls.courseTopic.replace(':topicSlug', `topic-${part.id}-${slugify(part.title)}`) : 
      urls.courseAssessment.replace(':assessmentSlug', `assessment-${part.id}-${slugify(part.title)}`);
    url = url.replace(':slug', slug.replace('course-', ''));
    const partProgress = progress.find(item => item.partId === part.id);
    const selected = part.id === selectedPartId;
    const required = part.type === 'assessment' || part.required;
    let status = courseComplete? 'complete' : '';
    status = status !== 'complete' && partProgress? partProgress.status : status;
    const accessiblePart = accessible.find(item => item.id === part.id);
    const open = isPartOpen(part.opening);

    return (
      <Fragment>
        { selected && <Icon className="selected" type={IconTypes.OutlineSelected} /> }
        { !selected && !required && <Icon type={IconTypes.InfoCircle} /> }
        { !selected && required && status === 'started' && <Icon type={IconTypes.Incomplete} /> }
        { !selected && required && status === 'complete' && <Icon type={IconTypes.CompleteFilled} /> }
        { !selected && required && status !== 'started' && status !== 'complete' && <Icon type={IconTypes.NotStarted} /> }
        { selected &&
          <span className="selected">{part.title}</span>
        }
        { !selected &&
          <div className="link not-selected">
            { authenticated && registered && (!isEmpty(accessiblePart) || transcript.status === 'complete') &&
              <Link to={url}>{part.title}</Link>
            }
            { (!authenticated || !registered || isEmpty(accessiblePart)) && transcript.status !== 'complete' &&
              <span>{part.title}</span>
            }
            { !open.isOpen &&
              <div className="opening">
                Opens on {open.str}
              </div>
            }
          </div>
        }
      </Fragment>
    );
  };

  const renderExam = exam => {
    if(exam.track !== 'cert-audit' && transcript.track !== exam.track && !isEmpty(transcript.track)) {
      // this part doesn't pertain to the track the student is registered in
      return (<Fragment></Fragment>);
    }

    const partProgress = progress.find(item => item.partId === exam.id);
    const selected = exam.id === selectedPartId;
    let status = courseComplete? 'complete' : '';
    status = status !== 'complete' && partProgress? partProgress.status : status;

    return (
      <Fragment>
        <div className="exam-heading">
          { selected && <Icon className="selected" type={IconTypes.OutlineSelected} /> }
          { !selected && status === 'started' && <Icon type={IconTypes.Incomplete} /> }
          { !selected && status === 'complete' && <Icon type={IconTypes.CompleteFilled} /> }
          { !selected && status !== 'started' && status !== 'complete' && <Icon type={IconTypes.NotStarted} /> }
          <h4 onClick={navigateToExam(exam)}>
            <span className={`title1 ${selected? 'selected' : 'not-selected'}`}>{exam.title}</span>
          </h4>
        </div>
      </Fragment>
    );
  };

  const navigateToExam = exam => event => {
    let url = urls.courseAssessment.replace(':assessmentSlug', `assessment-${exam.id}`);
    url = url.replace(':slug', slug.replace('course-', ''));
    navigate(url);
  };

  const navigateHome = event => {
    let url = urls.course.replace(':slug', slug.replace('course-', ''));
    navigate(url);
  };

  return (
    <div className="CourseOutline">
      { loaded &&
        <Fragment>
          <div className="CourseOutline-heading">
            <h3>Course Outline</h3>
            { courseHome &&
              <Button variant="outlined" color="primary" onClick={expandAll}>
                {expanded? 'Collapse All' : 'Expand All'}
              </Button>
            }
            { !courseHome &&
              <Button variant="outlined" color="primary" onClick={navigateHome}>Course Home</Button>
            }
          </div>
          <div className="CourseOutline-list">
            { lessons && lessons.length > 0 &&
              lessons.map(lesson => {
                return (
                  <div key={`lesson-${lesson.id}`} className="lesson">
                    {renderLesson(lesson)}
                  </div>
                );
              })
            }
            { exams && exams.length > 0 &&
              exams.map(exam => {
                return (
                  <div key={`exam-${exam.id}`} className="exam">
                    {renderExam(exam)}
                  </div>
                );
              })
            }
          </div>
        </Fragment>
      }
    </div>
  );
}

CourseOutline.defaultProps = {
  currentLessonId: null,
  selectedPartId: null,
  courseHome: false,
  updated: 0
};

CourseOutline.propTypes = {
  course: PropTypes.object,
  transcript: PropTypes.object,
  authenticated: PropTypes.bool,
  registered: PropTypes.bool,
  courseComplete: PropTypes.bool
};

function mapStateToProps(state) {
  return { 
    user: state.user,
    courseProgress: state.courseProgress
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    progressAPI: bindActionCreators(progressAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseOutline);
