import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Section from '../Section';
import Quote from '../../../common/Quote';
import LazyLoad from '../../../../layout/LazyLoad';


function QuoteInterstitial({ className, buttonVariant, text, name, buttonTitle, onClick, scrollPos }) {
  return (
    <Section className={`quote interstitial ${className}`}>
      <LazyLoad className="container" position={scrollPos}>
        <Quote 
          text={text}
          name={name}
        />
        { Boolean(buttonTitle) && Boolean(onClick) &&
          <Button className="action" variant={buttonVariant} onClick={onClick}>
            {buttonTitle}
          </Button>
        }
      </LazyLoad>
    </Section>
  );
};

QuoteInterstitial.defaultProps = {
  className: '',
  buttonVariant: 'outlined'
}

QuoteInterstitial.propTypes = {
  text: PropTypes.string,
  name: PropTypes.string,
  buttonTitle: PropTypes.string,
  onClick: PropTypes.func,
  scrollPos: PropTypes.number
};

export default QuoteInterstitial;