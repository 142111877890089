import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Section from '../Section';
import LazyLoad from '../../../../layout/LazyLoad';


function ImageContentInterstitial({ className, orientation, buttonVariant, imageURL, content, buttonTitle, onClick, scrollPos }) {
  return (
    <Section className={`image-content interstitial ${className} ${orientation}`}>
      <LazyLoad className="container" position={scrollPos}>
        { orientation === 'right' &&
          <div className="col1 large-display">
            <img src={imageURL} alt="Quote Feature" />
            { Boolean(buttonTitle) && Boolean(onClick) &&
              <Button className="action" variant={buttonVariant} onClick={onClick}>
                {buttonTitle}
              </Button>
            }
          </div>
        }
        {
          <div className={`${orientation === 'left'? 'col1' : 'col2'} large-display`}>
            {content}
          </div>
        }
        { orientation === 'left' &&
          <div className="col2 large-display">
            <img src={imageURL} alt="Quote Feature" />
            { Boolean(buttonTitle) && Boolean(onClick) &&
              <Button className="action" variant={buttonVariant} onClick={onClick}>
                {buttonTitle}
              </Button>
            }
          </div>
        }
        <div className="small-display">
          <img src={imageURL} alt="Quote Feature" />
          {content}
          { Boolean(buttonTitle) && Boolean(onClick) &&
            <Button className="action" variant={buttonVariant} onClick={onClick}>
              {buttonTitle}
            </Button>
          }
        </div>
      </LazyLoad>
    </Section>
  );
};

ImageContentInterstitial.defaultProps = {
  className: '',
  orientation: 'left',        // alt. 'right'
  buttonVariant: 'outlined'
}

ImageContentInterstitial.propTypes = {
  imageURL: PropTypes.string,
  content: PropTypes.object,        // should be React component
  buttonTitle: PropTypes.string,
  onClick: PropTypes.func,
  scrollPos: PropTypes.number
};

export default ImageContentInterstitial;