import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import logo from '../../images/icc-logoV2.png';
import Icon, { IconTypes } from '../common/Icon';
import PrivacyPrompt from './PrivacyPrompt';
import urls from '../../config/urls';


function Footer({ user }) {
  const [privacyPrompt, setPrivacyPrompt] = useState(true);

  const openContact = () => {
    window.open(urls.contactUs, '_blank');
  };

  return (
    <div className="AppFooter">
      { privacyPrompt &&
        <PrivacyPrompt onClose={() => setPrivacyPrompt(false)} />
      }
      <div className="AppFooter-main">
          
        <div className="AppFooter-branding">
          <Link to={urls.root} className="logo">
            <img src={logo} alt="Institute of Catholic Culture Logo" />
          </Link>
          <p className="description">
            The Institute of Catholic Culture is an adult catechetical organization, faithful to the Magisterium 
            of the Catholic Church, and dedicated to the Church’s call for a new evangelization.
          </p>
        </div>

        <div className="AppFooter-navigation">
          <div className="content-links">
            <div className="about-links">
              <h3>About the Institute</h3>
              <ul>
                <li><Link to={urls.about}>Mission</Link></li>
                <li><Link to={urls.curriculum}>Curriculum</Link></li>
                <li><Link to={urls.leadership}>Board Leadership</Link></li>
                <li><Link to={urls.staff}>Our Staff</Link></li>
                <li><Link to={urls.privacyPolicy}>Privacy Policy</Link></li>
              </ul>
            </div>

            <div className="program-links">
              <h3>Institute Programs</h3>
              <ul>
                <li><Link to={user.type === 'magdala-member'? urls.magdalaCoursesPage : urls.magdalaLandingPage}>Magdala Apostolate</Link></li>
                <li><Link to={urls.pilgrimage}>Holy Land Pilgrimage</Link></li>
                <li><Link to={urls.retreat}>Living Catholic</Link></li>
                <li><Link to={urls.missionPartners}>Mission Partners</Link></li>
                <li><Link to={urls.jobs}>Employment Opportunities</Link></li>
              </ul>
            </div>
          </div>

          <div className="share-links">
            <a href={urls.socialFacebookICC} target="_blank" rel="noopener noreferrer">
              <Icon type={IconTypes.ShareFacebook} inline={true} />
            </a>
            <a href={urls.socialInstagramICC} target="_blank" rel="noopener noreferrer">
              <Icon type={IconTypes.ShareInstagram} inline={true} />
            </a>
            <a href={urls.socialTwitterICC} target="_blank" rel="noopener noreferrer">
              <Icon type={IconTypes.ShareTwitter} inline={true} />
            </a>
            <a href={urls.socialYouTubeICC} target="_blank" rel="noopener noreferrer">
              <Icon type={IconTypes.ShareYouTube} inline={true} />
            </a>
            <a href="https://www.google.com/calendar/render?cid=instituteofcatholicculture%40gmail.com#main_7" target="_blank" rel="noopener noreferrer">
              Import our Google Calendar 
            </a>
          </div>
        </div>

        <div className="AppFooter-contact">
          <h3>Contact Us</h3>
          <p className="address">PO Box 670, Front Royal, VA 22630</p>
          <div className="phone"><Icon type={IconTypes.Phone} inline={true} />540-635-7155</div>
          <div className="email"><Icon type={IconTypes.Email} inline={true} />info@instituteofcatholicculture.org</div>
          <div className="AppFooter-contactButton">
            <Button variant="contained" onClick={openContact}>
              Contact Form
            </Button>
          </div>
        </div>
      </div>
      <div className="AppFooter-copyright">
        <p className="copyright">© Institute of Catholic Culture, All Rights Reserved</p>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {user: state.user}
};
  
export default connect(mapStateToProps)(Footer); 