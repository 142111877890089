import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Section from '../Section';
import SectionHeader from '../SectionHeader';
import ImageStoryCard from '../../../common/ImageStoryCard';
import VideoStoryCard from '../../../common/VideoStoryCard';
import Carousel from '../../../common/Carousel';
import BasicCarouselCard from '../../../common/BasicCarouselCard';
import LazyLoad from '../../../../layout/LazyLoad';


function DepthSection({ content, scrollPos }) {
  const { value: heading } = content['depth.heading'];
  const { value: sectionTitle1 } = content['depth.sectionTitle1'];
  const { value: sectionTitle2 } = content['depth.sectionTitle2'];
  const { value: story1 } = content['depth.story1'];
  const { value: story2 } = content['depth.story2'];
  const { value: story3 } = content['depth.story3'];
  const { value: video1 } = content['depth.video1'];
  const { value: video2 } = content['depth.video2'];
  const { value: carousel } = content['depth.carousel'];

  return (
    <Fragment>
      <Section className="depth top">
        <SectionHeader 
          heading={heading.title}
          subtext={heading.text}
        />
        <LazyLoad className="stories-lazy" position={scrollPos}>
          <h4 className="story-heading">{sectionTitle1}</h4>
          <div className="story-cards">
            <ImageStoryCard 
              imageURL={story1.image}
              altImageURL={story1.altImage}
              title={story1.title}
              subtitle={story1.subtitle}
              text={story1.text}
            />
            <ImageStoryCard 
              imageURL={story2.image}
              altImageURL={story2.altImage}
              title={story2.title}
              subtitle={story2.subtitle}
              text={story2.text}
            />
            <ImageStoryCard 
              imageURL={story3.image}
              altImageURL={story3.altImage}
              title={story3.title}
              subtitle={story3.subtitle}
              text={story3.text}
            />
          </div>
        </LazyLoad>
      </Section>
      <Section className="depth bottom">
        <LazyLoad className="videos-lazy" position={scrollPos}>
          <h4 className="section-heading">{sectionTitle2}</h4>
          <div className="video-container1">
            <VideoStoryCard 
              orientation="right"
              imageURL={video1.image}
              videoId={video1.videoId}
              videoType={video1.videoProvider}
              title={video1.title}
              text={video1.text}
            />
          </div>
          <div className="video-container2">
            <VideoStoryCard 
              orientation="left"
              imageURL={video2.image}
              videoId={video2.videoId}
              videoType={video2.videoProvider}
              title={video2.title}
              text={video2.text}
            />
          </div>
        </LazyLoad>
      </Section>
      <Section className="depth bottom-carousel">
        <div className="carousel-container">
          <LazyLoad className="carousel-lazy" position={scrollPos}>
            <Carousel title={carousel.title}>
              {
                carousel.cards.map((card, index) => {
                  const { image: imageURL, title, text } = card;
                  return (
                    <BasicCarouselCard 
                      key={`depth-carousel-card-${index}`}
                      id={`bc-card${index+1}`}
                      imageURL={imageURL}
                      title={title}
                      text={text}
                    />
                  );
                })
              }
            </Carousel>
          </LazyLoad>
        </div>
      </Section>
    </Fragment>
  );
};

DepthSection.propTypes = {
  content: PropTypes.object,
  scrollPos: PropTypes.number
};

export default DepthSection;