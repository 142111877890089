import React from 'react'; 
import TitleWithSubtext from '../../common/TitleWithSubtext';
import VideoEmbed from '../../common/media/VideoEmbed';
import PropTypes from 'prop-types';

function AboutSection({content}) {

	const { value: titleWithSubtext } = content['landingAbout.intro']; 
	const { value: video } = content['landingAbout.Video']; 

	return (
		<div className="Magdala-About">
			<TitleWithSubtext titleWithSubtext={titleWithSubtext} />
			<VideoEmbed
				video={{
					id: video.videoId,
					serviceProvider: video.videoProvider,
					title: video.title
				}}
				onProgress={() => {}}
			/>
		</div>
	)
}

AboutSection.propTypes = {
	content: PropTypes.object
}

export default AboutSection; 
