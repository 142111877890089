import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useAnalytics from '../../../hooks/useAnalytics';
import * as searchAPI from '../../../actions/search';
import EventList from '../../content/events/display/EventList';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import config from '../../../config/config';
import stateConfig from '../../../config/state';
import urls from '../../../config/urls';
import { resetViewToTopOfPage } from '../../../utils/utils';
import SEOMeta from '../../common/SEOMeta';


function Catechism({ search, searchAPI }) {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);
  useAnalytics('Catechism');
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if(loading && search.responseStatus === stateConfig.responseStatus.ERROR) {
      setError(search.responseCode);
      setLoading(false);
    } else if(loading && search.responseStatus === stateConfig.responseStatus.COMPLETE) {
      setResults(search.results);
      setLoading(false);
    }
  }, [loading, search.responseStatus, search.responseCode, search.results]);

  initialize = () => {
    resetViewToTopOfPage();
    const query = { type: 'Self-Paced Course', categories: 'catechetics' };
    searchAPI.searchWithFilters(query);
  };

  const clearPopMessage = () => {
    setError(null);
  }

  return (
    <div className="MainContent-container">
      { !results && !error && <LoadingMessage message="Loading..." /> }

      { error &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}>
          <p>We could not load the requested page.</p>
        </PopMessage>
      }

      { results &&
        <div className="CatechismCourses">
          <SEOMeta 
            title="Catechism Courses" 
            description="In-depth, self-paced certificate courses on the Catechism of the Catholic Church." />
          <EventList events={results} displayType={config.eventListTypes.BROWSE}
            heading="Catechism Courses" cardsPerRender={config.defaultCardsPerRender}
            moreText="View More Courses" moreLink={urls.courses} />
        </div>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {
    search: state.search
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    searchAPI: bindActionCreators(searchAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Catechism);
