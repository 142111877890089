import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import useRefDimensions from '../../../hooks/useRefDimensions';
import Icon, { IconTypes } from '../../common/Icon';
import { viewport } from '../../../utils/device';


function Carousel({ title, subtitle, defaultWidth, children }) {
  let containerRef = useRef(null);
  let cardsRef = useRef(null);
  let containerDimensions = useRefDimensions(containerRef);
  const [cardIndex, setCardIndex] = useState(1);
  const [cardWidth, setCardWidth] = useState(defaultWidth);

  useEffect(() => {
    if(containerDimensions.width) {
      const { isSmall } = viewport();
      const calcCardWidth = isSmall? containerDimensions.width : containerDimensions.width - 140;
      if(calcCardWidth !== cardWidth) {
        setCardWidth(calcCardWidth);
      }
    }
  }, [cardWidth, containerDimensions.width]);

  const navigate = useCallback(direction => () => {
    let index = direction === 'back'? cardIndex-1 : cardIndex+1;
    if(index < 1 || index > children.length) return;
    if(direction === 'back') {
      cardsRef.current.scrollLeft -= cardWidth;
    } else {
      cardsRef.current.scrollLeft += cardWidth;
    }
    setCardIndex(index);
  }, [cardIndex, cardWidth, children]);

  return (
    <div ref={containerRef} className="Carousel">
      <div className="Carousel-heading">
        <h3>{title}</h3>
        <h4>{subtitle}</h4>
      </div>
      <div className="Carousel-container">
        <div className={`nav-prev large-display ${cardIndex === 1? 'disabled' : 'active'}`} onClick={navigate('back')}>
          <div className="nav-button-bg">
            <Icon type={IconTypes.ArrowBack} />
          </div>
        </div>
        <div className="Carousel-cards" ref={cardsRef}>
          {
            children.map((child, index) => {
              if (React.isValidElement(child)) {
                return React.cloneElement(child, { containerStyle: { width: `${cardWidth}px` } });
              }
              return child;
            })
          }
        </div>
        <div className={`nav-next large-display ${cardIndex === children.length? 'disabled' : 'active'}`} onClick={navigate('next')}>
          <div className="nav-button-bg">
            <Icon type={IconTypes.ArrowForward} />
          </div>
        </div>
        <div className="nav-small-display">
          <div className={`nav-prev small-display ${cardIndex === 1? 'disabled' : 'active'}`} onClick={navigate('back')}>
            <div className="nav-button-bg">
              <Icon type={IconTypes.ArrowBack} />
            </div>
          </div>
          <div className={`nav-next small-display ${cardIndex === children.length? 'disabled' : 'active'}`} onClick={navigate('next')}>
            <div className="nav-button-bg">
              <Icon type={IconTypes.ArrowForward} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Carousel.defaultProps = {
  defaultWidth: 900
};

Carousel.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
};

export default Carousel;