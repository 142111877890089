import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import * as postAPI from '../../../actions/content/posts';
import stateConfig from '../../../config/state';
import config from '../../../config/config';
import validator from '../../../utils/validator';
const { isNotEmpty } = validator;


function LeadershipContent({ displayAdvisors, onComplete, posts, postAPI }) {
  const [loading, setLoading] = useState(true);
  const [about, setAbout] = useState(null);
  const [selectedDirector, setSelectedDirector] = useState(null);
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const aboutContent = posts.byId[`${stateConfig.keys.PAGE}.about`];
    if(loading && posts.ids && isNotEmpty(aboutContent)) {
      if(aboutContent.error) {
        onComplete(false, aboutContent.error);
        setLoading(false);
      } else {
        onComplete(true);
        setAbout(aboutContent);
        setLoading(false);
      }
    }
  }, [loading, onComplete, posts.ids, posts.byId]);

  initialize = () => {
    const aboutContent = posts.byId[`${stateConfig.keys.PAGE}.about`];
    if(aboutContent) {
      setAbout(aboutContent);
    } else {
      postAPI.getPageBySlug('about');
    }
  };

  const openDirector = director => () => {
    setSelectedDirector(director);
  };

  const onCloseDirector = () => {
    setSelectedDirector(null);
  }

  return (
    <div className="LeadershipContent">
      { selectedDirector &&
        <Dialog className="Leadership-dialog" open={true} onClose={onCloseDirector}>
          <DialogTitle className="director-name">{selectedDirector.name}</DialogTitle>
          <DialogContent>
            <DialogContentText className="director-description">
              <div dangerouslySetInnerHTML={{__html: selectedDirector.description}} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCloseDirector} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      }

      { !loading && Boolean(about) && Boolean(about.directors) &&
        <Fragment>
          <h3>Board of Directors</h3>
          <div className="Leadership-directors">
            {
              about.directors.map((director, index) => {
                const { image, name, title, description } = director;
                return (
                  <div key={`director-${index}`} className="director">
                    <img src={image} alt={name} />
                    <h4>{name}</h4>
                    <h5>{title}</h5>
                    { description &&
                      <Button variant="outlined" color="primary" onClick={openDirector(director)}>
                        More
                      </Button>
                    }
                  </div>
                );
              })
            }
          </div>
        </Fragment>
      }

      {/* Advisors content is being hidden for possible removal in the future
      { displayAdvisors && Boolean(about) && Boolean(about.advisors) && 
        <Fragment>
          <h3>Board of Advisors</h3>
          <div className="Leadership-advisors">
            {
              about.advisors.map((advisor, index) => {
                const { name, description } = advisor;
                return (
                  <div key={`advisor-${index}`} className="advisor">
                    <h4>{name}</h4>
                    <h5>{description}</h5>
                  </div>
                );
              })
            }
          </div>
        </Fragment>
      }
      */}
    </div>
  );
}

LeadershipContent.defaultProps = {
  displayAdvisors: true
};

LeadershipContent.propTypes = {
  onComplete: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    posts: state.posts 
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    postAPI: bindActionCreators(postAPI, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadershipContent);
