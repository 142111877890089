import React from 'react';
import PropTypes from 'prop-types';


function SectionHeader({ heading, subtext }) {

  const renderHeader = () => {
    const headerHTML = heading.replaceAll('[', "<span class='emphasis'>").replaceAll(']', '</span>').replaceAll("\\n", '<br />');
    return (
      <h3 dangerouslySetInnerHTML={{ __html: headerHTML }} />
    );
  };

  return (
    <div className="SectionHeader">
      {renderHeader()}
      { Boolean(subtext) &&
        <p>{subtext}</p>
      }
    </div>
  );
}

SectionHeader.propTypes = {
  heading: PropTypes.string,
  subtext: PropTypes.string
};

export default SectionHeader;