import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { truncate } from '../../utils/format';

const siteLogo = 'https://instituteofcatholicculture.net/wp-content/uploads/2019/10/icc-default-logo-large.jpg';

function SEOMeta(props) {
  let { title, description } = props;
  let pageTitle = truncate(title, 100);
  let pageDescription = description || 'The Institute of Catholic Culture is an adult catechetical organization dedicated to the Church’s call for a new evangelization';
  pageDescription = truncate(pageDescription, 150);
  let page = window.location.href;

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />

      {/*Twitter card meta*/}
      <meta name="twitter:site" content="@InstituteCC" />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDescription} />
      <meta name="twitter:creator" content="@InstituteCC" />

      {/*Open Graph meta*/}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={page} />
      <meta property="og:image" content={siteLogo} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:site_name" content="Institute of Catholic Culture" />
    </Helmet>
  );
}

SEOMeta.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

export default SEOMeta;