import React from 'react';
import PropTypes from 'prop-types';
import Section from '../Section';
import SectionHeader from '../SectionHeader';
import Quote from '../../../common/Quote';
import ImageQuoteCard from '../../../common/ImageQuoteCard';
import StoryNavigator, { StoryCard } from '../../../common/StoryNavigator';
import LazyLoad from '../../../../layout/LazyLoad';


function CommunitiesSection({ content, scrollPos }) {
  const { value: heading } = content['communities.heading'];
  const { value: sectionTitle1 } = content['communities.sectionTitle1'];
  const { value: sectionTitle2 } = content['communities.sectionTitle2'];
  const { value: quote1 } = content['communities.quote1'];
  const { value: quote2 } = content['communities.quote2'];
  const { value: quote3 } = content['communities.quote3'];
  const { value: { items: storyNavItems } } = content['communities.storyNav'];

  return (
    <Section className="communities">
      <SectionHeader 
        heading={heading.title}
        subtext={heading.text}
      />
      <LazyLoad className="quotes-lazy" position={scrollPos}>
        <ImageQuoteCard 
          orientation="left"
          imageURL={quote1.image}
          text={quote1.text}
          name={quote1.name}
        />
        <h4 className="sub-head1">{sectionTitle1}</h4>
        <ImageQuoteCard 
          orientation="right"
          imageURL={quote2.image}
          text={quote2.text}
          name={quote2.name}
        />
        <ImageQuoteCard 
          orientation="left"
          imageURL={quote3.image}
          text={quote3.text}
          name={quote3.name}
        />
      </LazyLoad>

      <LazyLoad className="stories-lazy" position={scrollPos}>
        <h4 className="sub-head2">{sectionTitle2}</h4>
        <StoryNavigator
          navItems={storyNavItems.map(item => {
            const { image: imageURL, title } = item;
            return { imageURL, title };
          })}
        >
          {
            storyNavItems.map((item, index) => {
              const { quote: { text, name }, storyContent } = item;
              return (
                <StoryCard key={`communities-storynav-${index}`}>
                  <Quote 
                    align="left"
                    text={text}
                    name={name}
                  />
                  <div dangerouslySetInnerHTML={{ __html: storyContent }}></div>
                </StoryCard>
              )
            })
          }
        </StoryNavigator>
      </LazyLoad>
    </Section>
  );
};

CommunitiesSection.propTypes = {
  content: PropTypes.object,
  scrollPos: PropTypes.number
};

export default CommunitiesSection;