import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAnalytics from '../../../hooks/useAnalytics';
import ReflectionsData from '../../data/Reflections';
import Heading from './Heading';
import Reflection from './Reflection';
import ReflectionsList from './ReflectionsList';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import stateConfig from '../../../config/state';
import config from '../../../config/config';
import urls from '../../../config/urls';
import { resetViewToTopOfPage } from '../../../utils/utils';
import validator from '../../../utils/validator';
import SEOMeta from '../../common/SEOMeta';
const { isNotEmpty } = validator;


function GospelReflections() {
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState(null);
  const [slug, setSlug] = useState(null);
  const [months, setMonths] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [reflectionsList, setReflectionsList] = useState(null);
  const [selectedReflections, setSelectedReflections] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  useAnalytics('Gospel Reflections');

  useEffect(() => {
    resetViewToTopOfPage();
  }, []);

  useEffect(() => {
    const slug = location.pathname.replace('/reflections/', '');
    if(location.pathname.includes('byzantine-gospel')) {
      // BGR hidden for the time being per Father's request in Oct 2023, may be permanently removed later
      //setType('byzantine');
      window.location = urls.reflectionsLatin;
      return;
    } else {
      setType('latin');
    }
    
    setSlug(slug);
    setMonths(null);
    setSelectedMonth(null);
    setReflectionsList(null);
    setSelectedReflections(null);
    setError(null);
    setLoading(true);
  }, [location]);

  const onReflectionsLoaded = (data) => {
    if(isNotEmpty(data.error)) {
      setError(data.error);
    } else {
      loadReflectionsData(data);
    }
  };

  const loadReflectionsData = data => {
    if(data.reflections.length === 1) { // single reflection
      setReflectionsList(data.reflections);
      setSelectedReflections(data.reflections);
    } else { // reflection list
      const monthList = generateListOfMonths(data.reflections);
      const filteredList = data.reflections.filter(filterReflections(monthList[0]));
      setMonths(monthList);
      setSelectedMonth(monthList[0]);
      setReflectionsList(data.reflections);
      setSelectedReflections(filteredList);
    }
  };

  const generateListOfMonths = data => {
    const dates = data.map(reflection => reflection.date);
    let months = [];

    dates.forEach(dateStr => {
      const date = new Date(dateStr);
      const month = config.monthNames[date.getMonth()];
      const year = date.getFullYear();
      const key = `${month} ${year}`;
      if(!months.includes(key)) {
        months.push(key);
      }
    });
    return months;
  };

  const onMonthChange = event => {
    const month = event.target.value;
    const filteredList = reflectionsList.filter(filterReflections(month));
    setSelectedMonth(month);
    setSelectedReflections(filteredList);
  };

  const filterReflections = selectedMonth => reflection => {
    const dateValues = selectedMonth.split(' ');
    const month = config.monthNames.findIndex(monthName => monthName === dateValues[0]);
    const year = parseInt(dateValues[1]);
    const reflectionDate = new Date(reflection.date);
    return reflectionDate.getMonth() === month && reflectionDate.getFullYear() === year;
  };

  const onReflectionSelection = (mediaType, data) => {
    if(mediaType === 'audio') {
      const url = type === 'latin'? urls.reflectionsLatinClassroomAudio : urls.reflectionsByzantineClassroomAudio;
      navigate(url.replace(':reflectionId', data.id).replace(':audioId', data.media.audio[0].id));
    } else {
      const url = type === 'latin'? urls.reflectionsLatinClassroomVideo : urls.reflectionsByzantineClassroomVideo;
      navigate(url.replace(':reflectionId', data.id).replace(':videoId', data.media.video[0].id));
    }
  };

  const clearPopMessage = () => {
    setError(null);
  };
  
  return (    
    <div className="MainContent-container">
      { !selectedReflections && !error && <LoadingMessage message="Loading..." /> }

      { loading && isNotEmpty(type) && isNotEmpty(slug) && <ReflectionsData type={type} slug={slug} onReflectionsLoaded={onReflectionsLoaded} /> }

      { error &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}>
          <p>We could not load the requested page.</p>
        </PopMessage>
      }

      { selectedReflections && selectedReflections.length > 0 &&
        <div className="GospelReflections">
          <SEOMeta 
            title={type === 'latin'? 'Sunday Gospel Reflections' : 'Byzantine Gospel Reflections'} 
            description="This weekly series offers an in-depth study of the lectionary readings." />
          <Heading type={type} />
          { reflectionsList.length === 1 && // base on full reflections, indv. month can have only one
            <div className="GospelReflections-content single">
              <Reflection reflection={selectedReflections[0]} onSelection={onReflectionSelection} />
              <div className="links">
                { type === 'latin' &&
                  <a href={urls.reflectionsLatin}>View All Reflections</a>
                }
                { /*type !== 'latin' && &&
                  BGR hidden for the time being per Father's request in Oct 2023, may be permanently removed later
                  <a href={urls.reflectionsByzantine}>View All Reflections</a>
                */}
              </div>
            </div>
          }
          { reflectionsList.length > 1 && // base on full reflections, indv. month can have only one
            <ReflectionsList months={months} selectedMonth={selectedMonth} onMonthChange={onMonthChange}
              reflections={selectedReflections} onSelection={onReflectionSelection} />
          }
        </div>
      }
    </div>
  );
}

export default GospelReflections;