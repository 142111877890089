import React from 'react';

function Section({ children, className }) {
  return (
    <div className={`AnnualReport-section ${className}`}>
      <div className="AnnualReport-content">
        {children}
      </div>
    </div>
  );
}

Section.defaultProps = {
  className: ''
};

export default Section;