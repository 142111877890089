import React, { Fragment } from 'react';
import urls from '../../../config/urls';


function DonationDisclaimer(props) {
  return (
    <Fragment>
      <p className="disclaimer-message">
        Please note that at this time the donation information displayed on this page will only show a record of donations made <strong>online on or after November 5, 2020.</strong>
      </p>
      <p className="disclaimer-message">
        If you have a question about a donation made before this date, or a donation that was made by a method other 
        than a credit card <a href={urls.contactUs} target="_blank" rel="noopener noreferrer">please contact our staff.</a>
      </p>
    </Fragment>
  )
}

export default DonationDisclaimer;