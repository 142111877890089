import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GeoMap from './GeoMap';
import StoryNavigator, { StoryCard } from '../StoryNavigator';

const mapPinIcon = 'https://instituteofcatholicculture.net/wp-content/uploads/2022/11/map-pin-alt.png';


function MapHighlights({ title, sections, locations, highlights }) {
  const [selectedLocation, setSelectedLocation] = useState(locations[0]);

  const handleLocationSelection = location => {
    setSelectedLocation(location);
  };

  return (
    <div className="MapHighlights">
      <GeoMap 
        className="large-display"
        title={title}
        locations={locations}
        highlights={highlights}
        onSelection={handleLocationSelection}
      />
      <div className="MapHighlights-sections large-display">
        {
          sections.map((section, index) => {
            return (
              <div key={`section-${index}`} 
                className={`section ${(Boolean(selectedLocation) && selectedLocation.id === section.id)? 'show' : 'hide'}`}>
                <div className="title">
                  <h5>
                    <img src={mapPinIcon} alt="Map Pin" />
                    <span>{section.title}</span>
                  </h5>
                </div>
                <div className="details">
                  <img src={section.imageURL} alt={section.title} />
                  <p>{section.text}</p>
                </div>
              </div>
            );
          })
        }
      </div>
      <h4 className="MapHighlights-title small-display">{title}</h4>
      <StoryNavigator 
        className="MapHighlights-navigator small-display"
        navItems={sections.map(section => {
          const { imageURL, title } = section;
          return { imageURL, title };
        })}
      >
        {
          sections.map((section, index) => {
            const { text } = section;
            return (
              <StoryCard key={`map-highlights-storynav-${index}`}>
                <p>{text}</p>
              </StoryCard>
            )
          })
        }
      </StoryNavigator>
    </div>
  );
}

MapHighlights.defaultProps = {
  locations: null,    
  highlights: []      
};

MapHighlights.propTypes = {
  title: PropTypes.string,
  sections: PropTypes.array,    // info to display for selected location
  locations: PropTypes.array,   // selectable locations
  highlights: PropTypes.string   // countries that should be filled/highlighted
};

export default MapHighlights;